import { memo, useCallback, useMemo, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';

import { PageContextProps, useApp } from '../../contextProviders/AppProvider';
import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';

import GratiIcon from './GratiIcon';
import GratiSettingsMenu from './GratiSettingsMenu';

interface GratiAppBarProps {
  sx?: SxProps<Theme>;
  isTopLevelPage?: boolean;
}

const NavMenu = memo(
  ({
    anchorElNav,
    eligiblePages,
    handleCloseNavMenu,
  }: {
    anchorElNav: HTMLElement | null;
    eligiblePages: PageContextProps[];
    handleCloseNavMenu: () => void;
  }) => {
    if (eligiblePages.length === 0) return null;

    return (
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {eligiblePages.map((page) => (
          <MenuItem component={Link} key={page.name} to={page.to} onClick={handleCloseNavMenu}>
            <Typography textAlign="center" className="AppBarText">
              {page.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    );
  }
);

NavMenu.displayName = 'NavMenu';

const GratiAppBar = memo((props: GratiAppBarProps) => {
  const { sx, isTopLevelPage } = props;
  const navigate = useNavigate();
  const { actions, getPageTitle, isHomePage, pageContexts } = useApp();
  const { isLoggedIn } = useAuth();
  const { getAdminOrgs } = useData();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const isMenuItemDisplayed = useCallback(
    (page: PageContextProps) => {
      return (
        (page.showLogin && isLoggedIn && (!page.showOnlyAdmin || getAdminOrgs().length > 0)) ||
        (page.showLogout && !isLoggedIn)
      );
    },
    [isLoggedIn, getAdminOrgs]
  );

  const eligiblePages = useMemo(() => pageContexts.filter(isMenuItemDisplayed), [pageContexts, isMenuItemDisplayed]);

  const isNavMenuDisplayed = useCallback(() => {
    return isTopLevelPage && eligiblePages.length > 0;
  }, [isTopLevelPage, eligiblePages.length]);

  const handleOpenNavMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  }, []);

  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const handleBackClick = useCallback(() => {
    if (isLoggedIn) {
      if (isHomePage) {
        navigate('/');
      } else {
        navigate(-1);
      }
    }
  }, [isLoggedIn, isHomePage, navigate]);

  return (
    <AppBar position="fixed" sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Toolbar disableGutters>
        {/* Menu or Back Button */}
        {(eligiblePages.length > 0 && isNavMenuDisplayed()) || !isTopLevelPage ? (
          <Box className="NavMenu" sx={{ flexGrow: isNavMenuDisplayed() ? 1 : 0, display: { xs: 'flex' } }}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={isNavMenuDisplayed() ? handleOpenNavMenu : handleBackClick}
              edge="start"
              className="AppBarIcon"
              sx={{ display: { xs: 'flex', md: isHomePage ? 'none' : 'flex' } }}
            >
              {isTopLevelPage ? <MenuIcon /> : <ArrowBackIcon />}
            </IconButton>
            <NavMenu anchorElNav={anchorElNav} eligiblePages={eligiblePages} handleCloseNavMenu={handleCloseNavMenu} />
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1 }} />
        )}

        {/* Title */}
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...(isTopLevelPage && {
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }),
          }}
          className="Headline"
        >
          {isTopLevelPage && (
            <IconButton className="AppBarIcon">
              <GratiIcon className="AppBarIcon" />
            </IconButton>
          )}
          <Typography
            sx={{
              display: { xs: 'flex' },
              flexGrow: 0,
            }}
            className={isHomePage ? 'AppBarText-home' : 'AppBarText'}
          >
            {getPageTitle()}
          </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        {/* Right side items */}
        <Stack direction="row" alignItems="center">
          {actions.map((action) => (
            <IconButton key={action.name} onClick={action.handleClick} className="AppBarIcon">
              <SvgIcon component={action.icon} />
            </IconButton>
          ))}
        </Stack>
        <GratiSettingsMenu sx={{ flexGrow: 0 }} />
      </Toolbar>
    </AppBar>
  );
});

GratiAppBar.displayName = 'GratiAppBar';

export default GratiAppBar;
