import { ReactElement, useState, memo, useCallback } from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

import { useData } from "../../contextProviders/DataProvider";
import { OrgMembershipStatus } from "../../enums/OrgMembershipStatus";
import Org from "../../types/Org";
import Person from "../../types/Person";
import PersonDirectory from "../person/PersonDirectory";


export interface OrgMemberDirectoryProps {
  org: Org;
  orgMembershipStatus?: OrgMembershipStatus;
}

export const OrgMemberDirectory = memo((props: OrgMemberDirectoryProps) : ReactElement => {
  const { org, orgMembershipStatus = OrgMembershipStatus.ALL } = props;
  const { deactivateOrgMembership, deleteOrgMembership, addOrgMembership } = useData();
  const [openId, setOpenId] = useState<string | null>(null);
  const [confirmingId, setConfirmingId] = useState<string | null>(null);

  const getOrgMembershipStatus = (person: Person, orgId: string) => {
    if (person.orgIds?.includes(orgId)) {
      return 'active';
    }
    if (person.inactiveOrgIds?.includes(orgId)) {
      return 'inactive';
    }
    return null;
  };

  const renderActions = useCallback((person: Person) => {
    const isOpen = openId === person.itemId;
    const isConfirming = confirmingId === person.itemId;

    const handleDeactivatePerson = (personId: string) => {
      if (org) {
        deactivateOrgMembership(org, personId);
      }
      console.log("handleDeactivatePerson", personId);
    }
  
    const handleRemovePerson = (personId: string) => {
      if (org) {
        deleteOrgMembership(org, personId);
      }
      console.log("handleRemovePerson", personId);
    }
  
    const handleAddPerson = (personId: string) => {
      if (org) {
        addOrgMembership(org, personId);
      }
      console.log("handleAddPerson", personId);
    }

    return {
      isOpen,
      actions: (
        <Stack direction="row" spacing={1}>
          {!isOpen && (
            <IconButton onClick={() => setOpenId(person.itemId)}>
              <MoreVertIcon />
            </IconButton>
          )}
          {isOpen && !isConfirming && 
            getOrgMembershipStatus(person, org.itemId) === 'inactive' && (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<PersonAddIcon />}
              onClick={() => {
                handleAddPerson(person.itemId);
                setOpenId(null);
              }}
            >
              Activate
            </Button>
          )}
          {isOpen && !isConfirming && 
            getOrgMembershipStatus(person, org.itemId) === 'inactive' && (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<PersonRemoveIcon />}
              onClick={() => setConfirmingId(person.itemId)}
            >
              Remove
            </Button>
          )}
          {isOpen && !isConfirming && 
            getOrgMembershipStatus(person, org.itemId) === 'active' && (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<PersonOffIcon />}
              onClick={() => {
                handleDeactivatePerson(person.itemId);
                setOpenId(null);
              }}
            >
              Deactivate
            </Button>
          )}
          {isOpen && !isConfirming && (
            <Button 
              size="small" 
              variant="outlined" 
              color="secondary" 
              onClick={() => setOpenId(null)}
            >
              Cancel
            </Button>
          )}
          {isConfirming && (
            <Stack direction="row" spacing={1}>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<PersonRemoveIcon />}
                onClick={() => {
                  handleRemovePerson(person.itemId);
                  setConfirmingId(null);
                  setOpenId(null);
                }}
              >
                Confirm Removal
              </Button>
              <Button 
                size="small" 
                variant="outlined" 
                color="secondary" 
                onClick={() => {
                  setConfirmingId(null);
                  setOpenId(null);
                }}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </Stack>
      )
    }
  }, [openId, confirmingId, org, deactivateOrgMembership, deleteOrgMembership, addOrgMembership]);

  return (
    <PersonDirectory
      orgId={org.itemId}
      orgMembershipStatus={orgMembershipStatus}
      renderActions={renderActions}
    />
  );
});

OrgMemberDirectory.displayName = 'OrgMemberDirectory';