import { useState, useEffect } from 'react';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';

import type Event from '../types/Event';

interface UseEventProps {
  orgId?: string;
  eventId?: string;
}

export default function useEvent(props: UseEventProps) {
  const { orgId, eventId } = props;
  const { isGod } = useAuth();
  const { isUserLoading, userProfile, getEvent } = useData();
  const [event, setEvent] = useState<Event | null>(null);
  const [isEventLoading, setIsEventLoading] = useState(true);
  const [eventError, setEventError] = useState<Error | null>(null);

  useEffect(() => {
    let isMounted = true;

    async function loadEvent(loadEventId: string) {
      try {
        setIsEventLoading(true);

        if (!isGod && orgId && !userProfile?.orgIds?.includes(orgId)) {
          throw new Error('Not authorized to access this organization');
        }

        const fetchedEvent = orgId && eventId ? await getEvent(orgId, eventId) : null;
        if (isMounted) {
          setEvent(fetchedEvent);
        }
      } catch (err) {
        if (isMounted) {
          setEventError(err instanceof Error ? err : new Error('Failed to load event'));
        }
      } finally {
        if (isMounted) {
          setIsEventLoading(false);
        }
      }
    }

    if (!isUserLoading && userProfile && orgId && eventId) {
      loadEvent(eventId);
    }

    return () => {
      isMounted = false;
    };
  }, [isGod, isUserLoading, eventId, orgId, getEvent, userProfile]);

  return { event, isEventLoading, eventError };
}