import { ReactElement, useCallback, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import PersonEditor from '../components/person/PersonEditor';
import PersonView from '../components/person/PersonView';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import usePerson from '../dataProviders/usePerson';
import Person from '../types/Person';
import PersonDetails from '../types/PersonDetails';

interface PersonPageProps {
  isEditMode?: boolean;
}

export default function PersonPage(props: PersonPageProps): ReactElement {
  const { updateActions, setPageName } = useApp();
  const { canEditPerson, canEditPersonDetails,userProfile, updatePerson, updatePersonDetails } = useData();
  const navigate = useNavigate();

  const isEditMode = props.isEditMode ?? false;
  const { personId } = useParams();

  // if no profileId is passed in, show the logged-in user's profile
  const requestingPersonId = personId ?? userProfile?.itemId;

  console.log('personId', personId);

  const { person, isPersonLoading, personError } = usePerson(personId);

  const handleEditClick = useCallback(() => {
    navigate('/profile-edit/' + requestingPersonId, { replace: true });
  }, [navigate, requestingPersonId]);

  useEffect(() => {
    if (person) {
      setPageName(person.name);
    }
  }, [person, setPageName]);

  useEffect(() => {
    if (person && canEditPerson(person)) {
      updateActions([{ name: 'Edit', handleClick: handleEditClick, icon: EditIcon }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person, updateActions]);

  const handleCancelEdit = useCallback(() => {
    navigate('/profile/' + personId, { replace: true });
  }, [navigate, personId]);

  const handleSubmitEdit = useCallback((updatedPerson: Person, updatedPersonDetails?: PersonDetails) => {
    if (updatedPerson) {
      updatePerson(updatedPerson.itemId, updatedPerson);
      if (updatedPersonDetails && canEditPersonDetails(updatedPerson)) {
        updatePersonDetails(updatedPersonDetails.itemId, updatedPersonDetails);
      }
    }
    navigate('/profile/' + personId, { replace: true });
  }, [navigate, personId, canEditPersonDetails, updatePerson, updatePersonDetails]);

  if (personError) {
    console.error(personError);
    return <div>Error loading person</div>;
  }

  return (
    <GratiPageWrapper isContentLoading={isPersonLoading}>
      {person && (
        <>
          {isEditMode ? (
            <PersonEditor
              person={person}
              isNewPerson={false}
              onCancel={handleCancelEdit}
              onSubmit={handleSubmitEdit}
            />
          ) : (
            <PersonView 
              person={person} 
            />
          )}
        </>
      )}
    </GratiPageWrapper>
  );
}
