import { memo, ReactElement, useMemo } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';

import useEvents from '../../dataProviders/useEvents';
import Event from '../../types/Event';
import { IsEventOver } from '../../utils/utils';
import Hourglass from '../common/Hourglass';

import EventListItem from './EventListItem';
import EventScheduleSection from './EventScheduleSection';

interface EventsBySeason {
  name: string;
  seasons: {
    [seasonId: string]: {
      name: string;
      events: Event[];
    };
  };
}

export interface EventScheduleProps {
  isSortedbyLeague?: boolean;
  isUpcoming: boolean;
}

function EventSchedule(props: EventScheduleProps): ReactElement {
  const { isSortedbyLeague, isUpcoming } = props;
  const { events, isEventsLoading, eventsError } = useEvents({ filter: isUpcoming ? 'upcoming' : 'all' });

  // For league-sorted view
  const sortedEvents = useMemo(() => {
    if (!events) return {};

    const sorted: { [leagueId: string]: EventsBySeason } = {};

    for (const event of events) {
      if (IsEventOver(event) === isUpcoming) continue;

      const { leagueId, leagueName, seasonId = '0', seasonName = '' } = event;

      if (!sorted[leagueId]) {
        sorted[leagueId] = { name: leagueName, seasons: {} };
      }

      if (!sorted[leagueId].seasons[seasonId]) {
        sorted[leagueId].seasons[seasonId] = { name: seasonName, events: [] };
      }

      sorted[leagueId].seasons[seasonId].events.push(event);
    }

    return sorted;
  }, [events, isUpcoming]);

  if (isEventsLoading) return <Hourglass />;

  if (eventsError) {
    console.error('Error loading events', eventsError);
    return <Alert severity="error">Error loading events</Alert>;
  }

  // For upcoming events (isUpcoming = true): keep events where IsEventOver = false
  // For past events (isUpcoming = false): keep events where IsEventOver = true
  const filteredEvents = events?.filter((event) => IsEventOver(event) === !isUpcoming);

  const listContent = isSortedbyLeague
    ? Object.entries(sortedEvents).map(([leagueId, league]) => (
        <ListItem disableGutters key={`section-${leagueId}`} sx={{ pl: 0, width: '100%' }}>
          <List
            subheader={
              <ListSubheader>
                <Typography variant="h6" color="primary.main">
                  {league.name}
                </Typography>
              </ListSubheader>
            }
            sx={{ pl: 0, width: '100%' }}
          >
            {Object.entries(league.seasons).map(([seasonId, season]) => (
              <EventScheduleSection
                key={`section-${leagueId}-${seasonId}`}
                title={season.name}
                events={season.events}
                isLeagueDisplayed={false}
                isUpcoming={isUpcoming}
                sectionId={seasonId}
              />
            ))}
          </List>
        </ListItem>
      ))
    : filteredEvents?.map((event) => <EventListItem key={event.itemId} event={event} isLeagueDisplayed={true} />);

  return (
    <Box>
      <List sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        p: 0,
        '& ul': { padding: 0 },
        '& li': { padding: 0 },
      }}>
        {listContent}
      </List>
    </Box>
  );
}

export default memo(EventSchedule);
