import { ReactElement } from "react";

import { ref } from "firebase/storage";

import { Link } from "react-router-dom";

import { useDownloadURL } from "react-firebase-hooks/storage";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";

import BusinessIcon from "@mui/icons-material/Business";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { imagesRef } from "../../firebase";
import Org from '../../types/Org';

interface OrgRowProps {
  org: Org,
  actions?: ReactElement<typeof Stack>,
  isDividerDisplayed?: boolean
  isPending?: boolean;
  onAcceptInvitation?: (orgId: string) => void;
}

export const OrgListItem = (props: OrgRowProps ) : ReactElement => {
  const { org, onAcceptInvitation } = props;
  const isPending = props.isPending ?? false;
  const isDividerDisplayed = props.isDividerDisplayed ?? false;
  const [imageUrl] = useDownloadURL((org && org.logo && org.logo.length > 0) ? ref(imagesRef, org.logo) : null);

  const StyledLeagueAvatar = (): ReactElement => {
    return (<Avatar alt={org.name} src={imageUrl} sx={{height: 48, width: 48, textAlign: "center", alignItems: "center", justifyContent: "center", color: "primary.onContainer", backgroundColor: "primary.container"}}>
      <BusinessIcon sx={{color: "primary.onContainer", backgroundColor: "primary.container"}}/>
    </Avatar>);
  }

  return (
    <ListItem 
      sx={{ 
        pl: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
      divider={isDividerDisplayed} 
      className={org.isTest ? "test" : ""}
    >
      <Box 
        component={Link}
        to={`/org/${org.itemId}`}
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          flex: 1,
          textDecoration: 'none',
          color: 'inherit'
        }}
      >
        <ListItemAvatar>
          <StyledLeagueAvatar />
        </ListItemAvatar>
        <ListItemText 
          primary={org.name} 
          primaryTypographyProps={{
            variant: "title", 
            color: "surface.contrastText"
          }}
          secondary={org.description}
          secondaryTypographyProps={{
            variant: "body2",
            color: "surface.onVariant"
          }}
        />
      </Box>
      {isPending && (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<CheckCircleIcon />}
          onClick={(e) => {
            e.preventDefault(); // Prevent navigation when clicking the button
            onAcceptInvitation?.(org.itemId);
          }}
          sx={{ ml: 2 }}
        >
          Accept Invitation
        </Button>
      )}
    </ListItem>
  );
}