import { collection, DocumentReference } from 'firebase/firestore';
import { doc } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Course from '../types/Course';

export default function useCourse(courseId: string | undefined) {
  const { userProfile } = useData();
  const [course, isCourseLoading, courseError] = 
    useDocumentData<Course>(
      courseId && userProfile ? doc(collection(firestore, 'course'), 
      courseId
    ) as DocumentReference<Course> : null);

  return { course, isCourseLoading, courseError };
}