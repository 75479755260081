import { 
  ReactElement,
} from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useAuth } from "../../contextProviders/AuthProvider";
import useSeasons from "../../dataProviders/useSeasons";
import League from "../../types/League";
import { CurrentDateTime } from "../../utils/utils";
import Hourglass from "../common/Hourglass";
import SeasonStandingCsvUpload from "../season/SeasonStandingCsvUpload";

import LeagueCard from "./LeagueCard";
import LeagueMembers from "./LeagueMembers";
import LeagueResults from "./LeagueResults";

type LeagueViewProps = {
  league: League;
};

export default function LeagueView(props: LeagueViewProps ): ReactElement {
  const { league } = props;
  const { isGod } = useAuth();

  const { seasons, isSeasonsLoading, seasonsError } = useSeasons({ league: league });

  const currentSeason = () => {
    const currentTime = CurrentDateTime();

    if (league && seasons && seasons.length > 0) {
      const currentSeason = seasons.find(
        (season) => currentTime > season.startTime && currentTime <= season.endTime
      );
      return currentSeason ? currentSeason.itemId : '';
    } else {
      return '';
    }
  };

  const LeagueView = (): ReactElement => {
    // TODO: This is a hack to get the current season. We need to refactor this.
    const thisSeason = seasons?.find(season => season.itemId === currentSeason());
    if (league && thisSeason) {
      return (
        <Stack spacing={2}>
          <LeagueCard league={league} variant="none" isClickable={false} />
          <Divider sx={{ display: { sm: 'none' } }} />
          <Box>
            <Typography variant="headline">Results</Typography>
          </Box>
          <LeagueResults league={league} />
          {isGod && <SeasonStandingCsvUpload season={thisSeason} />}
          <Box>
            <Typography variant="title">Members</Typography>
          </Box>
          <LeagueMembers league={league} isEditable={true} />
        </Stack>
      );
    } else {
      return <></>;
    }
  }; 

  if (isSeasonsLoading) {
    return <Hourglass />
  } else if (seasonsError) {
    return <Alert severity="error">Error loading seasons: {seasonsError.message}</Alert>
  } else if (seasons) {
    return (
      <Container disableGutters className="BodyContainer" sx={{ flexGrow: 1}}>
        <LeagueView />
      </Container>
    );
  } else {
    return <> </>
  }
}