import { 
  ReactElement, 
} from "react";

import Org from '../../types/Org';
import GratiCard from "../common/GratiCard";

interface OrgCardProps {
  org: Org;
  variant?: "elevation" | "outlined" | "none";
  isClickable?: boolean;
}

export default function OrgCard(props: OrgCardProps): ReactElement {
  const { org, isClickable } = props;
  const variant = props.variant ?? "outlined";

  return (
    <GratiCard 
      to={isClickable ? `/org/${org.itemId}` : undefined} 
      key={org.itemId}
      variant={variant}
      overline={""}
      avatar={org.logo}
      title={org.name} 
      description={org.description}
    />
  );
}