import { useEffect } from "react";
import { useState } from "react";

import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import League from "../types/League";

interface UseLeagueProps {
  orgId?: string;
  leagueId?: string;
}

export default function useLeague(props: UseLeagueProps) {
  const { orgId, leagueId } = props;
  
  const { isGod } = useAuth();
  const { isUserLoading, userProfile, getLeague } = useData();
  const [league, setLeague] = useState<League | null | undefined>(null);
  const [isLeagueLoading, setIsLeagueLoading] = useState(true);
  const [leagueError, setLeagueError] = useState<Error | null>(null);

  useEffect(() => {
    let isMounted = true;

    async function loadLeague(loadOrgId: string, loadLeagueId: string) {
      try {
        setIsLeagueLoading(true);

        if (!isGod && !userProfile?.orgIds?.includes(loadOrgId)) {
          throw new Error('Not authorized to access this organization');
        }

        const fetchedLeague = await getLeague(loadOrgId, loadLeagueId);
        if (isMounted) {
          setLeague(fetchedLeague);
        }
      } catch (err) {
        if (isMounted) {
          setLeagueError(err instanceof Error ? err : new Error('Failed to load league'));
        }
      } finally {
        if (isMounted) {
          setIsLeagueLoading(false);
        }
      }
    }

    if (!isUserLoading && userProfile && orgId && leagueId) {
      loadLeague(orgId, leagueId);
    }

    return () => {
      isMounted = false;
    };
  }, [isGod, orgId, leagueId, getLeague, isUserLoading, userProfile]);

  if (!orgId || !leagueId) {
    throw new Error('Invalid orgId or leagueId');
  }

  return { league, isLeagueLoading, leagueError };
}