import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";

import GolfCourseIcon from '@mui/icons-material/GolfCourse';

import GratiPageWrapper from "../components/common/GratiPageWrapper";
import Hourglass from "../components/common/Hourglass";
import { useApp } from "../contextProviders/AppProvider";
import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import { validateEmail } from "../utils/validators";

const inputProps = {
  minLength: 6
}

interface FormState {
  values: {
    displayName: string;
    emailAddress: string;
    orgName: string;
    password: string;
  };
  touched: {
    emailAddress: boolean;
  };
  errors: {
    emailAddress?: string;
  };
}

export default function AcceptOrgInvitation(): ReactElement {
  const { orgName, email, name } = useParams();
  const navigate = useNavigate();
  const { setPageName } = useApp();
  const { auth, isLoggedIn } = useAuth(); 
  const { userProfile } = useData();

  const decodedEmail = email ? decodeURIComponent(email) : "";
  const decodedName = name ? decodeURIComponent(name) : "";
  const decodedOrgName = orgName ? decodeURIComponent(orgName) : "";

  const [formState, setFormState] = useState<FormState>({
    values: {
      displayName: decodedName,
      emailAddress: decodedEmail,
      orgName: decodedOrgName,
      password: ""
    },
    touched: {
      emailAddress: false
    },
    errors: {}
  });

  const [ 
    createUserWithEmailAndPassword, 
    newUser, 
    isCreating, 
    createError, 
  ] = useCreateUserWithEmailAndPassword(auth);

  useEffect(() => {
    setPageName("Accept Invitation");
  }, [setPageName]);

  useEffect(() => {
    if (newUser && isLoggedIn && userProfile) {
      navigate('/');
    }
  }, [isLoggedIn, userProfile, newUser, navigate]);

  useEffect(() => {
    if (createError) {
      setFormState(prev => ({
        ...prev,
        touched: {
          ...prev.touched,
          emailAddress: true
        },
        errors: {
          ...prev.errors,
          emailAddress: createError.code === 'auth/email-already-in-use' 
            ? 'Email already in use'
            : createError.code === 'auth/invalid-email'
            ? 'Invalid email address'
            : prev.errors.emailAddress,
        }
      }));
    }
  }, [createError]);


  const handleChange = (field: keyof FormState['values']) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setFormState(prev => ({
      ...prev,
      values: {
        ...prev.values,
        [field]: value
      }
    }));

    if (field === 'emailAddress') {
      const isValid = validateEmail(value);
      setFormState(prev => ({
        ...prev,
        errors: {
          ...prev.errors,
          emailAddress: !isValid ? 'Invalid email address' : undefined
        }
      }));
    }
  };

  const handleBlur = (field: keyof FormState['touched']) => () => {
    setFormState(prev => ({
      ...prev,
      touched: {
        ...prev.touched,
        [field]: true
      }
    }));
  };

  const handleSubmit = async () => {
    if (!isCreating) {
      await createUserWithEmailAndPassword(
        formState.values.emailAddress, 
        formState.values.password
      );
    }
  };

  return (
    <GratiPageWrapper isNoAuthRequired={true}>
      <Stack spacing={2} sx={{display: 'flex' , flexDirection: 'column', alignItems: 'center', py: 2}}>
        <Typography gutterBottom variant="h5" component="h5">
          You are approved to join {orgName ? "the " + orgName : "your golf league"} on GratiGolf!
        </Typography>
        <Typography variant="body2" color="surface.contrastText">
          To accept your invitation, you just need to enter your name, email and password.
        </Typography>
      </Stack>
      <Box
        component="form" 
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}          
      >
        {!isCreating && !newUser && 
          <Stack spacing={2} sx={{display: 'flex' , flexDirection: 'column', alignItems: 'center'}}>
            <TextField
              fullWidth
              required
              autoFocus={!formState.values.displayName}
              id="displayName"
              label="Name"                
              aria-label="name"
              helperText="First and last name"
              value={formState.values.displayName}
              onChange={handleChange('displayName')}
            />
  
            <TextField
              fullWidth
              required
              error={formState.touched.emailAddress && Boolean(formState.errors.emailAddress)}
              autoFocus={formState.values.displayName && !formState.values.emailAddress ? true : false}
              id="email"
              label="Email"
              onBlur={handleBlur('emailAddress')}
              aria-label="Email"
              helperText={formState.touched.emailAddress && formState.errors.emailAddress ? 
                formState.errors.emailAddress : "Valid email address"}
              value={formState.values.emailAddress}
              onChange={handleChange('emailAddress')}
            />
  
            <TextField
              fullWidth
              required
              autoFocus={formState.values.displayName && formState.values.emailAddress ? true : false}
              id="password"
              type="password"
              label="Password"
              aria-label="Password"                
              helperText="Don't make it obvious!"
              value={formState.values.password}
              inputProps={inputProps}
              onChange={handleChange('password')}
            />
            <Button 
              type="submit" 
              variant="contained" 
              startIcon={<GolfCourseIcon/>}
              disabled={
                !formState.values.displayName || 
                !formState.values.emailAddress || 
                !formState.values.password ||
                Boolean(formState.errors.emailAddress)}
            >
              Accept
            </Button> 
          </Stack>
        }
        {isCreating && !newUser && 
          <Stack 
            spacing={2} 
            direction="column" 
            alignItems="center" 
            sx={{ py: 4 }}
          >
            <Hourglass />
            <Typography variant="h6">
              Creating your profile...
            </Typography>
          </Stack>
        }
        {!isCreating && newUser && 
          <Stack spacing={2} sx={{display: 'flex' , flexDirection: 'column', alignItems: 'center'}}>
            <Typography variant="h6">
              Your profile has been created. Preparing your home.
            </Typography>
          </Stack>
        }
      </Box>
    </GratiPageWrapper>  );
}
