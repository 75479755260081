import { 
  forwardRef,
  ReactElement,
  useEffect,
  useState, 
} from "react";

import { 
  getDownloadURL, 
  ref as firebaseStorageRef
} from "firebase/storage";

import { Link } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Stack from "@mui/material/Stack";
import {
  styled, 
  useThemeProps 
} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import ConstructionIcon from '@mui/icons-material/Construction';

import { imagesRef } from "../../firebase";

import GratiMap from "./GratiMap";

export interface GratiCardProps {
  key: string;
  to?: string; // Card header will link this url
  // isBordered?: boolean;
  // isOnlyHeader?: boolean;
  // Props are in the order they are rendered.
  overline?: string;
  tagline?: string;
  avatar?: string;
  title?: string;
  subtitle?: string;
  location?: string;
  placeId?: string;
  description?: string;
  footer?: string[];
  actionbar?: ReactElement;
  isTest?: boolean;
  variant: "elevation" | "outlined" | "test" | "none";
}

interface GratiCardOwnerState extends GratiCardProps {

}

const GratiCardRoot = styled(Card, {
  name: 'GratiCard',
  slot: 'root',
  overridesResolver: (props, styles) => {
    return styles.root;
  },
})<{ ownerState: GratiCardOwnerState}>(({ ownerState }) => ({
}));

const GratiCardOverline = styled('div', { 
  name: 'GratiCard',
  slot: 'overline',
  overridesResolver: (props, styles) => {
    return styles.overline;
  },
})<{ ownerState: GratiCardOwnerState }>(() => ({
}));

const GratiCardTagline = styled('div', { 
  name: 'GratiCard',
  slot: 'tagline',
  overridesResolver: (props, styles) => {
    return styles.tagline;
  },
})<{ ownerState: GratiCardOwnerState }>(() => ({
}));

const GratiCardAvatar = styled(Avatar, { 
  name: 'GratiCard',
  slot: 'avatar',
  overridesResolver: (props, styles) => {
    return styles.avatar;
  },
})<{ ownerState: GratiCardOwnerState }>(() => ({
}));

const GratiCardTitle = styled('span', { 
  name: 'GratiCard',
  slot: 'title',
  overridesResolver: (props, styles) => {
    return styles.title;
  },
})<{ ownerState: GratiCardOwnerState }>(({ theme }) => ({
  ...theme.typography.title,
}));

const GratiCardSubtitle = styled('span', { 
  name: 'GratiCard',
  slot: 'subtitle',
  overridesResolver: (props, styles) => {
    return styles.subtitle;
  },
})<{ ownerState: GratiCardOwnerState }>(() => ({
}));

const GratiCardLocation = styled('p', {
  name: 'GratiCard',
  slot: 'location',
  overridesResolver: (props, styles) => {
    return styles.location;
  },
})<{ ownerState: GratiCardOwnerState }>(() => ({
}));

const GratiCardDescription = styled('div', {
  name: 'GratiCard',
  slot: 'description',
  overridesResolver: (props, styles) => {
    return styles.description;
  },
})<{ ownerState: GratiCardOwnerState }>(() => ({
}));

const GratiCardFooter = styled('div', {
  name: 'GratiCard',
  slot: 'header',
  overridesResolver: (props, styles) => {
    return styles.footer;
  },
})<{ ownerState: GratiCardOwnerState }>(() => ({
}));

const GratiCardActionbar = styled('div', {
  name: 'GratiCard',
  slot: 'actionbar',
  overridesResolver: (props, styles) => {
    return styles.actionbar;
  },
})<{ ownerState: GratiCardOwnerState }>(() => ({
}));

export default forwardRef<HTMLDivElement, GratiCardProps>(
  function GratiCard(inProps, ref) {
    const props = useThemeProps({ props: inProps, name: 'GratiCard' });
    
    const { 
      to, 
      overline, 
      tagline, 
      avatar, 
      title, 
      subtitle, 
      location, 
      placeId,
      description, 
      footer, 
      actionbar, 
      variant, 
      isTest,
      ...other 
    } = props;

    // const isBordered = props.isBordered ?? true;
    // const isOnlyHeader = props.isOnlyHeader ?? false;
    const ownerState = { ...props, variant: variant ?? "outlined" };
    const [logo, setLogo] = useState<string | null>(null);

    useEffect(() => {
      if (avatar) {
        getDownloadURL(firebaseStorageRef(imagesRef, avatar)).then((url) => {
          setLogo(url);
      }).catch((error) => {
          console.log(error);
        });
      }
    }, [avatar]);

    return (
        <GratiCardRoot ref={ref} ownerState={ownerState} {...other} className={isTest ? "test" : ""}> 
          <CardActionArea component={to ? Link : 'div'} to={to} disableRipple={to ? false : true} sx={{pl: 0}}>
            { (overline || logo) && 
              <GratiCardOverline ownerState={ownerState} className={isTest ? "test" : ""}>
                <Stack direction="row" spacing={1} sx={{alignItems: "center", pl: 1}}>
                  { logo && 
                    <GratiCardAvatar alt='brand logo' id={"eventLogo"} src={logo} ownerState={ownerState} />
                  }
                  <Typography variant="body1">
                    {overline}
                  </Typography> 
                </Stack> 
              </GratiCardOverline>
            }
            { tagline && 
              <GratiCardTagline ownerState={ownerState} sx={{pl: logo ? 7 : 1}} className={isTest ? "test" : ""}>
                {tagline}
              </GratiCardTagline>
            }
            <Stack spacing={1} sx={{my: 2, pl: 1}}>
              <span>
                { isTest && 
                  <ConstructionIcon fontSize="small" color="error" sx={{pr: 1}} />
                }
                { title && 
                  <GratiCardTitle ownerState={ownerState} className={isTest ? "test" : ""}>
                    {title}
                  </GratiCardTitle>
                }
                { subtitle && 
                  <GratiCardSubtitle ownerState={ownerState} className={isTest ? "test" : ""}>
                    {subtitle}
                  </GratiCardSubtitle>
                }
              </span>
              {placeId && <GratiMap placeId={placeId} sx={{ height: 200, width: '100%' }}/>}
              {location && 
                <GratiCardLocation ownerState={ownerState} className={isTest ? "test" : ""}>
                  {location}
                </GratiCardLocation>
              }
              {description && 
                <GratiCardDescription ownerState={ownerState} className={isTest ? "test" : ""}>
                  {description}
                </GratiCardDescription>
              }
              {footer && 
                <GratiCardFooter ownerState={ownerState} className={isTest ? "test" : ""}>
                  {footer.map((f) => <p key={f}>{f}</p>)}
                </GratiCardFooter>
              }
            </Stack>
          </CardActionArea>
          <CardActions>
            { actionbar && 
              <GratiCardActionbar ownerState={ownerState} className={isTest ? "test" : ""}>
                {actionbar}
              </GratiCardActionbar>
            }
          </CardActions>
        </GratiCardRoot>
      );
    }
  );
