import { memo, ReactElement } from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import Event from '../../types/Event';

type EventGolfSettingsEditorProps = {
  event: Event;
  onUpdate: (updates: Partial<Event>) => void;
}

const EventGolfSettingsEditor = memo(function EventGolfSettingsEditor({ 
  event, 
  onUpdate 
}: EventGolfSettingsEditorProps): ReactElement {
  return (
    <Stack direction="row" spacing={2} sx={{ display: "flex", alignItems: "center" }}>
      <TextField
        id="numteetimes"
        label="Tee Times"
        inputProps={{ 
          inputMode: 'numeric', 
          pattern: '[0-9]*' 
        }}
        value={event.numTeeTimes}
        onChange={({ target }) => {
          const numTeeTimes = parseInt(target.value || '0', 10);
          onUpdate({
            numTeeTimes,
            maxPlayers: numTeeTimes * 4
          });
        }}
      />
      <TextField
        id="maxPlayers"
        label="# of Golfers"
        inputProps={{ 
          inputMode: 'numeric', 
          pattern: '[0-9]*' 
        }}
        value={event.maxPlayers}
        onChange={({ target }) => 
          onUpdate({
            maxPlayers: parseInt(target.value || '0', 10)
          })
        }
      />
    </Stack>
  );
});

export default EventGolfSettingsEditor;