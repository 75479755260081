import { ReactElement, useEffect, useState } from 'react';

import { getDownloadURL, ref } from 'firebase/storage';

import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import GolfCourseIcon from '@mui/icons-material/GolfCourse';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import { imagesRef } from '../firebase';
import { validateAddress, validateEmail, validateGHIN, validatePhone } from '../utils/validators';

export default function Apply(): ReactElement {
  const welcomeImageFileName = 'welcome.png';
  const [welcomeImageUrl, setWelcomeImageUrl] = useState('');
  const orgId = ''; // Handle the ability to default to an org based on URL or empty if not specified
  const [displayName, setDisplayName] = useState<string>('');
  const [mailingAddress, setMailingAddress] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [ghin, setGHIN] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [isApplicationAccepted, setIsApplicationAccepted] = useState<boolean>(false);
  const [isAddressEdited, setIsAddressEdited] = useState<boolean>(false);
  const [isAddressValid, setIsAddressValid] = useState<boolean>(false);
  const [isEmailEdited, setIsEmailEdited] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isGHINEdited, setIsGHINEdited] = useState<boolean>(false);
  const [isGHINValid, setIsGHINValid] = useState<boolean>(false);
  const [isPhoneEdited, setIsPhoneEdited] = useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const { setPageName } = useApp();
  const { addApplication } = useData();

  useEffect(() => {
    getDownloadURL(ref(imagesRef, welcomeImageFileName))
      .then((url) => {
        setWelcomeImageUrl(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setPageName("Apply to SWGA");
  }, [setPageName]);

  const handleSubmit = () => {
    if (isEmailValid && isPhoneValid && isAddressValid && isGHINValid) {
      setIsCreating(true);
      // sendEmailVerification(auth.currentUser);
      addApplication(orgId, displayName, mailingAddress, email, ghin, phone)
        .then(() => {
          setIsApplicationAccepted(true);
        })
        .catch((error) => {
          console.log(error);
        });
      setIsCreating(false);
    }
  };

  const handleMailingAddressChange = (val: string) => {
    if (val === '') {
      setIsAddressEdited(false);
    } else {
      setIsAddressValid(validateAddress(val));
    }
    setMailingAddress(val);
  };

  const handleEmailChange = (val: string) => {
    if (val === '') {
      setIsEmailEdited(false);
    } else {
      setIsEmailValid(validateEmail(val));
    }
    setEmail(val);
  };

  const handleGHINChange = (val: string) => {
    if (val === '') {
      setIsGHINEdited(false);
    } else {
      setIsGHINValid(validateGHIN(val));
    }
    setGHIN(val);
  };

  const handlePhoneChange = (val: string) => {
    if (val === '') {
      setIsPhoneEdited(false);
    } else {
      setIsPhoneValid(validatePhone(val));
    }
    setPhone(val);
  };

  const ApplicationStatus = (): ReactElement => {
    return (
      <Container>
        <Card sx={{ maxWidth: 640 }}>
          <CardMedia component="img" image={welcomeImageUrl} alt="Welcome" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Your application has been received!
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Thank you for applying. We will review your application and get back to you shortly.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    );
  };

  const ApplicationForm = (): ReactElement => {
    return (
      <Stack spacing={2} component="form" onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Typography gutterBottom variant="h5" component="div">
          SWGA Application
        </Typography>
        <Typography variant="body2" color="text.secondary">
          To apply to the SWGA, please provide us with a few details.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          We will review your application and get back to you shortly.
        </Typography>
        <TextField
          required
          autoFocus={true}
          id="displayName"
          label="Name"
          aria-label="name"
          helperText="First and last name"
          defaultValue={displayName}
          onChange={({ target }) => setDisplayName(target.value)}
        />

        <TextField
          required
          error={isEmailEdited && isEmailValid === false}
          id="email"
          label={isEmailEdited && isEmailValid === false ? 'Error' : 'Email'}
          onBlur={(e) => {
            e.target.value.length > 0 && setIsEmailEdited(true);
          }}
          aria-label="Email"
          helperText={isEmailEdited && isEmailValid === false ? 'Invalid email address' : 'Valid email address'}
          defaultValue={email}
          onChange={(e) => handleEmailChange(e.target.value)}
        />

        <TextField
          required
          error={isPhoneEdited && isPhoneValid === false}
          id="phone"
          fullWidth
          label={isPhoneEdited && isPhoneValid === false ? 'Error' : 'Phone'}
          onBlur={(e) => {
            e.target.value.length > 0 && setIsPhoneEdited(true);
          }}
          type="Phone"
          aria-label="Phone number"
          helperText={isPhoneEdited && isPhoneValid === false ? 'Invalid phone number' : 'Phone number'}
          aria-required="true"
          defaultValue={phone}
          onChange={(e) => handlePhoneChange(e.target.value)}
        />

        <TextField
          required
          error={isAddressEdited && isAddressValid === false}
          id="mailingaddress"
          fullWidth
          label={isAddressEdited && isAddressValid === false ? 'Error' : 'Mailing Address'}
          onBlur={(e) => {
            e.target.value.length > 0 && setIsAddressEdited(true);
          }}
          type="Address"
          aria-label="Mailing Address"
          helperText={isAddressEdited && isAddressValid === false ? 'Invalid address' : 'Mailing Address'}
          aria-required="true"
          defaultValue={mailingAddress}
          onChange={(e) => handleMailingAddressChange(e.target.value)}
        />

        <TextField
          error={isGHINEdited && isGHINValid === false}
          id="ghin"
          fullWidth
          label={isGHINEdited && isGHINValid === false ? 'Error' : 'GHIN'}
          onBlur={(e) => {
            e.target.value.length > 0 && setIsGHINEdited(true);
          }}
          type="GHIN"
          aria-label="USGA Golf Handicap Index Number"
          helperText={isGHINEdited && isGHINValid === false ? 'Invalid GHIN' : 'GHIN'}
          aria-required="true"
          defaultValue={ghin}
          onChange={(e) => handleGHINChange(e.target.value)}
        />

        {isCreating ? (
          <Typography>Creating your application...</Typography>
        ) : (
          <Button type="submit" variant="contained" startIcon={<GolfCourseIcon />}>
            Apply
              </Button>
        )}
        <Typography variant="body2" color="secondary.text" align="center">
          Already have an account? <Link to="/login">Login here</Link>
        </Typography>
      </Stack>
    );
  }

  return (
    <GratiPageWrapper isNoAuthRequired={true}>
      <>
        {isApplicationAccepted ? <ApplicationStatus /> : <ApplicationForm />}
      </>
    </GratiPageWrapper>
  );
}
