import { and, collection, CollectionReference, or, orderBy, Query } from "firebase/firestore";
import { query, where } from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import { OrgMembershipStatus } from "../enums/OrgMembershipStatus";
import { firestore } from "../firebase";
import Person from "../types/Person";
import { useMemo } from "react";

interface UsePersonsProps {
  isActiveUser?: boolean;
  orgMembershipStatus?: OrgMembershipStatus;
  orgId?: string;
}

export default function usePersons(props: UsePersonsProps) {
  const { isActiveUser, orgMembershipStatus, orgId } = props;
  const { isGod } = useAuth();
  const { isOrgMember, userProfile, getAdminOrgs, getMemberOrgs  } = useData();

  // Memoize these values
  const adminOrgs = useMemo(() => getAdminOrgs(), [getAdminOrgs]);
  const memberOrgs = useMemo(() => getMemberOrgs(), [getMemberOrgs]);
  
  console.log("usePersons input:", { 
    isActiveUser, 
    orgMembershipStatus, 
    orgId, 
    isGod, 
    userProfile 
  });

  const getProfilesQuery = () => {
    const activeConditions = [];
    const orgConditions = [];
    const testConditions = [];
    const orgIds: string[] = [];
    // We are getting profiles for a specific org.

    if (orgId) {
      (isGod || isOrgMember(orgId)) && orgIds.push(orgId);
    } else {
      memberOrgs?.length > 0 && orgIds.push(...memberOrgs);
    }

    if (isGod && isActiveUser === false) {
      activeConditions.push(where('isActive', '==', false));
    } else {
      activeConditions.push(where('isActive', '==', true));
    }

    if (isGod) {
      testConditions.push(where('isTest', 'in', [true, false]));
    } else {
      testConditions.push(where('isTest', '==', false));
    }

    switch (orgMembershipStatus) {
      case OrgMembershipStatus.INACTIVE:
        (isGod || orgIds.every(org => adminOrgs.includes(org))) && 
        orgConditions.push(where('inactiveOrgIds', 'array-contains-any', orgIds));
        break;
      case OrgMembershipStatus.ALL:
        (isGod || orgIds.every(org => adminOrgs.includes(org))) && 
        orgConditions.push(
          or(
            where('orgIds', 'array-contains-any', orgIds),
            where('inactiveOrgIds', 'array-contains-any', orgIds)
          )
        )
        break;
      default:
        orgConditions.push(where('orgIds', 'array-contains-any', orgIds));
        break;
    }
    console.log("orgIds", orgIds);
    console.log("adminOrgs", adminOrgs);
    console.log("memberOrgs", memberOrgs);
    console.log("isGod", isGod);
    console.log("isActiveUser", isActiveUser);
    console.log("orgMembershipStatus", orgMembershipStatus);
    console.log("activeConditions", activeConditions);
    console.log("orgConditions", orgConditions);
    console.log("testConditions", testConditions);

    return query(
      collection(firestore, 'profile') as CollectionReference<Person>,
      and(
        ...activeConditions,
        ...orgConditions,
        ...testConditions,
      ),
      orderBy('name')
    ) as Query<Person>;
  }

  // Memoize the query
  const qProfiles = useMemo(() => {
    console.log("Building query...");
    return getProfilesQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveUser, orgMembershipStatus, orgId, isGod, adminOrgs, memberOrgs]);

  const [persons, isPersonsLoading, personsError] = useCollectionData<Person>(qProfiles);

  return { persons, isPersonsLoading, personsError };   
}
