import { 
  MouseEvent, 
  ReactElement, 
} from "react";

import { sendEmailVerification } from "firebase/auth";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import GratiPageWrapper from "../components/common/GratiPageWrapper";
import { useAuth } from "../contextProviders/AuthProvider";

export default function Verify(): ReactElement {
  const { auth } = useAuth(); 


  /* const handleVerifyEmail = (auth: any, actionCode: any) => {
    applyActionCode(auth, actionCode!)
      .then((res) => {
        //SUCCESS: EMAIL VERIFIED
        console.log(res, 'user UID ?');
        // updateDoc(doc(db, 'Data', res.user.uid), {
        //   verifiedEmail: true,
        // });
      })
      .catch((error) => {
        console.log(error, 'Cannot verify email');
      });
  }; */

  const resendClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (auth.currentUser) {
      sendEmailVerification(auth.currentUser, {url:"https://swga.golf/emailverified?confirm_email=true"});
  }};

  return (
    <GratiPageWrapper isNoAuthRequired={true}>
      <Box sx={{ p: 2 }}>
        <Alert severity="error" action={
          <Button variant='outlined' color="error" size="small"   
              onClick={resendClickHandler}
          >
            Resend
          </Button>}>
          <AlertTitle>Verify your Email</AlertTitle>
          An email was sent to you to verify your 
          email account is correct. Please go to your email and click the link. 
          If you need the email resent, click Resend.
        </Alert>
      </Box>
    </GratiPageWrapper>
  );
}
