import { ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { useData } from '../../contextProviders/DataProvider';
import usePersons from '../../dataProviders/usePersons';
import { OrgMembershipStatus } from '../../enums/OrgMembershipStatus';
import Person from '../../types/Person';
import Hourglass from '../common/Hourglass';

import PersonListItem from './PersonListItem';

interface PersonDirectoryProps {
  orgId?: string;
  orgMembershipStatus?: OrgMembershipStatus;
  isActiveUser?: boolean;
  renderActions?: (person: Person) => {
    actions: ReactElement;
    isOpen: boolean;
  };
  pageTitle?: string;
}

export default function PersonDirectory(props: PersonDirectoryProps): ReactElement {
  const { 
    orgId,
    orgMembershipStatus,
    isActiveUser,
    pageTitle,
    renderActions,
  } = props;

  const { userProfile } = useData();

  console.log('PersonDirectory render', {
    orgId,
    orgMembershipStatus,
    isActiveUser,
    userProfileId: userProfile?.itemId,
    time: new Date().toISOString()
  });

  const { persons, isPersonsLoading, personsError } = 
    usePersons({ 
      isActiveUser,
      orgMembershipStatus,
      orgId,
    });

  const getPersonStatus = (person: Person): boolean => {
    if (orgId) {
      return person.orgIds?.includes(orgId) ?? false;
    } else {
      return person.orgIds?.some(id => userProfile?.orgIds?.includes(id)) ?? false;
    }
  }

  if (isPersonsLoading) {
    console.log('isPersonsLoading:', isPersonsLoading);
    return <Hourglass />;
  } else if (personsError) {
    console.log('personsError:', personsError);
    return <Alert severity="error">Error loading members {personsError.message}</Alert>;
  } else if (!persons || persons.length === 0) {
    return <Alert severity="error">No members</Alert>;
  } else {
    return (
      <Container disableGutters className="SectionContainer">
        {pageTitle && 
          <Typography align="left" gutterBottom variant="headline">
            {pageTitle}
          </Typography>
        }
        <Box>
          <List
            sx={{
              width: '100%',
              height: '100%',
              position: 'relative',
              overflow: 'auto',
              p: 0,
              '& ul': { padding: 0 },
              '& li': { padding: 0 },
            }}
            subheader={<li />}
          >
            { persons?.flatMap((person: Person) => {
                const actionData = renderActions?.(person);
                return (
                  <PersonListItem
                  key={person.itemId}
                  person={person}
                    isActive={getPersonStatus(person)} 
                    isClickable={true}
                    isDividerDisplayed={true}
                    renderActions={actionData?.actions}
                    isActionsOpen={actionData?.isOpen}
                  />
                );
              } 
            ) }
          </List>
        </Box>
      </Container>
    );
  }
};
