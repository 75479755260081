import { ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import LeagueEditor from '../components/league/LeagueEditor';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import useOrg from '../dataProviders/useOrg';
import DefaultObjects from '../types/DefaultObjects';
import League from '../types/League';
import Person from '../types/Person';

export default function LeagueCreatePage(): ReactElement {
  const orgId = useParams().orgId;
  const { setPageName } = useApp();
  const { isOrgAdmin, userProfile } = useData();
  const { org, isOrgLoading, orgError } = useOrg(orgId);

  const createNewLeague = (): League => {
    return {
      ...DefaultObjects.League,
      creatorId: userProfile?.itemId || '',
      orgName: org?.name || '',
      orgId: org?.itemId || '',
    };
  };

  useEffect(() => {
    setPageName('Create League');
  }, [setPageName]);

  const validateUser = (person: Person | undefined) => {
    return person && org && isOrgAdmin(org.itemId) ? true : false;
  };

  return (
    <GratiPageWrapper validateUser={validateUser} isContentLoading={isOrgLoading}>
      <>
        {org && (
          <Box>
            <LeagueEditor league={createNewLeague()} isNewLeague={true} />
          </Box>
        )}
        {orgError && (
          <Box>
            <Typography component="body">Error loading org {orgError.message}</Typography>
          </Box>
        )}
      </>
    </GratiPageWrapper>
  );
}
