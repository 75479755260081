import { useMemo } from 'react';

import { collectionGroup, query, where, orderBy } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import League from '../types/League';

export default function useLeaguesWithAdminRights(): {
  leagues: League[];
  isLeaguesLoading: boolean;
  leaguesError: Error | undefined;
} {
  const { isGod } = useAuth();
  const { getAdminOrgs, adminLeagues } = useData();

  const leaguesQuery = useMemo(() => {
    const adminOrgs = getAdminOrgs();
    console.log('Creating leagues query:', { adminOrgs, adminLeagues, isGod });

    if (!adminOrgs.length && !adminLeagues.length && !isGod) return null;

    const conditions = [];

    if (adminOrgs.length) {
      conditions.push(where('orgId', 'in', adminOrgs));
    }

    conditions.push(where('isTest', 'in', [isGod, false, null]));
    conditions.push(orderBy('name'));

    return query(collectionGroup(firestore, 'league'), ...conditions);
  }, [getAdminOrgs, adminLeagues, isGod]);

  const [orgLeagues, isLeaguesLoading, leaguesError] = useCollectionData(leaguesQuery, {
    snapshotListenOptions: { includeMetadataChanges: false },
  });

  const leagues = useMemo(() => {
    if (!orgLeagues) return [];

    // Create a Set of league IDs we already have from orgs
    const existingLeagueIds = new Set(orgLeagues.map((league) => league.itemId));

    // Get leagues we have direct admin rights to that aren't already included
    const directLeagues = adminLeagues
      .filter((leagueId) => !existingLeagueIds.has(leagueId))
      .map((leagueId) => orgLeagues.find((l) => l.itemId === leagueId))
      .filter(Boolean);
    // Combine and sort all leagues
    return [...orgLeagues, ...directLeagues].sort((a, b) => {
      if (!a?.name || !b?.name) return 0;
      return a.name.localeCompare(b.name);
    });
  }, [orgLeagues, adminLeagues]);

  const result = useMemo(() => {
    console.log('Creating new leagues result object');
    return {
      leagues: leagues as League[],
      isLeaguesLoading,
      leaguesError,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leagues]);

  return result;
}
