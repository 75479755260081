import { ReactNode } from 'react';

import Box from "@mui/material/Box";

import { APIProvider } from '@vis.gl/react-google-maps';

import { GOOGLE_PLACES_API_KEY } from "../utils/GoogleMapsUtils";
import { useOnlineStatus } from '../utils/useOnlineStatus';

// Define libraries array outside component
const GOOGLE_MAPS_LIBRARIES: ["places"] = ["places"];

interface MapsProviderProps {
  children: ReactNode;
}

export function MapsProvider({ children }: MapsProviderProps) {
  const { isOnline } = useOnlineStatus();
  
  if (!isOnline) {
    return (
      <Box>
        {children}
      </Box>
    );
  } else {
    return (
      <APIProvider 
        apiKey={GOOGLE_PLACES_API_KEY}
        libraries={GOOGLE_MAPS_LIBRARIES}>
        {children}
      </APIProvider>
    );
  }
}