import { and, collection, orderBy, Query } from "firebase/firestore";
import { query, where } from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";
import Course from "../types/Course";

interface UseCoursesProps {
  courseIdList?: string[] | undefined;
}
export default function useCourses(props: UseCoursesProps) {
  const courseIdList = props.courseIdList ?? undefined;
  const { isGod } = useAuth();
  const { userProfile } = useData();

  // *************** Course loaders ****************
  const qCourses = userProfile ? courseIdList ? 
    courseIdList.length > 0 ?
      query(
        collection(firestore, 'course') as Query<Course>, 
        and(
          where("isTest", "in", [isGod, false]),
          where("itemId", "in", courseIdList),
        ),
        orderBy("name")
      ) : null : 
      query(
      collection(firestore, 'course') as Query<Course>, 
      where("isTest", "in", [isGod, false]),

      orderBy("name")
    ) : null;

  const [courses, isCoursesLoading, coursesError] = useCollectionData(qCourses);

  return { courses: courses ?? [], isCoursesLoading, coursesError };   
}
