import { memo, ReactElement } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';

import Event from '../../types/Event';

type EventMembershipTypeEditorProps = {
  event: Event;
  onUpdate: (updates: Partial<Event>) => void;
}

const EventMembershipTypeEditor = memo(function EventMembershipTypeEditor({ 
  event, 
  onUpdate 
}: EventMembershipTypeEditorProps): ReactElement {
  return (
    <Stack direction="row" spacing={2} sx={{ display: "flex", alignItems: "center" }}>
      <FormControl component="fieldset">
        <FormControlLabel
          value="top"
          control={
            <Checkbox
              checked={event.isMemberMember}
              onChange={({ target }) => onUpdate({ isMemberMember: target.checked })}
            />
          }
          label="Member/Member?"
          labelPlacement="top"
        />
      </FormControl>
      <FormControl component="fieldset">
        <FormControlLabel
          value="top"
          control={
            <Checkbox
              checked={event.isMemberGuest}
              onChange={({ target }) => onUpdate({ isMemberGuest: target.checked })}
            />
          }
          label="Member/Guest?"
          labelPlacement="top"
        />
      </FormControl>
    </Stack>
  );
});

export default EventMembershipTypeEditor;