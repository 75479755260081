import { and, collection, orderBy } from "firebase/firestore";
import { query, where } from "firebase/firestore";
import { Query } from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";
import League from "../types/League";
import Membership from "../types/Membership";


interface LeagueMembershipsProps {
  league: League;
}

export default function useLeagueMemberships(props: LeagueMembershipsProps) {
  const { league } = props;
  const { userProfile } = useData();

  const qLeagueMemberships = 
    userProfile && 
    userProfile.orgIds && 
    userProfile.orgIds.length > 0 && 
    userProfile.orgIds.includes(league.orgId) ? 
      query(
        collection(firestore, 'org/' + league.orgId + '/membership/'),
        and(
          where('isActive', '==', true),
        where('orgId', 'in', userProfile.orgIds),
        where('leagues', 'array-contains', league.itemId),
      ),
      orderBy('name')
    ) as Query<Membership> : null;

  const [
    leagueMemberships, 
    isLeagueMembershipsLoading, 
    isLeagueMembershipsError
  ] = useCollectionData<Membership>(qLeagueMemberships);

  return { leagueMemberships: leagueMemberships ?? [], isLeagueMembershipsLoading, isLeagueMembershipsError };   
}
