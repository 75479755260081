import { memo, ReactElement } from 'react';

import Stack from '@mui/material/Stack';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Event from '../../types/Event';
import { ToDayJS, FromDayJS } from '../../utils/utils';

type EventSignupTimeEditorProps = {
  event: Event;
  onUpdate: (updates: Partial<Event>) => void;
}

const EventSignupTimeEditor = memo(function EventSignupTimeEditor({ 
  event, 
  onUpdate 
}: EventSignupTimeEditorProps): ReactElement {
  return (
    <Stack direction="row" spacing={2} sx={{ display: "flex", alignItems: "center" }}>
      <DateTimePicker
        label="Sign Up Begins at"
        format="MM/DD/YY hh:mm a"
        value={ToDayJS(event.signupOpensAt, event.timezone)}
        onChange={(newDate) => {
          if (newDate) {
            const opensAt = FromDayJS(newDate);
            onUpdate({
              signupOpensAt: opensAt,
              // If new opens date is after current close date, update close date
              signupClosesAt: opensAt > event.signupClosesAt ? opensAt : event.signupClosesAt
            });
          }
        }}
      />
      <DateTimePicker
        label="Sign Up Deadline"
        format="MM/DD/YY hh:mm a"
        value={ToDayJS(event.signupClosesAt, event.timezone)}
        onChange={(newDate) => {
          if (newDate) {
            onUpdate({
              signupClosesAt: FromDayJS(newDate)
            });
          }
        }}
      />
    </Stack>
  );
});

export default EventSignupTimeEditor;