import { 
  ReactElement, 
  useState 
} from "react";

import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useData } from "../../contextProviders/DataProvider";
import useCourses from "../../dataProviders/useCourses";
import Event from '../../types/Event';
import EventDay from "../../types/EventDay";
import { CurrentDateTime, DisplayDate, DisplayDates, DisplayDateTime, DisplayTime, IsEndOfDay } from "../../utils/utils";
import GratiCard from "../common/GratiCard";

import EventRegBar from "./EventRegBar";

interface EventCardProps {
  event: Event;
  variant?: "elevation" | "outlined" | "test" | "none";
  isOnlyHeader?: boolean;
  isClickable?: boolean;
}

export default function EventCard( props: EventCardProps): ReactElement {
  const { event, isClickable } = props;
  const variant = event.isTest ? "test" : props.variant ?? "outlined";
  const navigate = useNavigate();
  // const isBordered = props.isBordered ?? true;
  // const isOnlyHeader = props.isOnlyHeader ?? false;

  const { deleteEvent, isOrgAdmin, isLeagueAdmin } = useData();

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const courseIds = [...new Set(
    event.days
      .map(day => day.courseId)
      .filter((id): id is string => id !== undefined && id.trim() !== '')
  )];

  const { courses, isCoursesLoading, coursesError } = useCourses({ courseIdList: courseIds });

  const deleteThisEvent = (event: Event) => {
    console.log(`Deleting event ${event.itemId}`);
    deleteEvent(event);
    setDeleteConfirm(false);
  };

  const isSignupOpen = () => {
    const currentDateTime = CurrentDateTime();
    return (currentDateTime > event.signupOpensAt && currentDateTime < event.signupClosesAt);
  }

  const statusText = () => {
    const currentDateTime = CurrentDateTime();
    if (event) {
      if (isSignupOpen()) {
        const isEndOfDay = IsEndOfDay(event.signupClosesAt);
        const closeTime = DisplayTime(event.signupClosesAt, event.timezone, isEndOfDay ? " " : ", h:mmA");
        return (
          "Signup open until " + 
          DisplayDate(event.signupClosesAt.toDate(), event.timezone, isEndOfDay ? "MMMM D" : "MMM D") + closeTime
        );
      } else if (currentDateTime < event.signupOpensAt) {
        return ("Signups open at " + DisplayDateTime(event.signupOpensAt, event.timezone));
      } else if (currentDateTime > event.days[event.days.length - 1].datetime) {
        return ("Event has passed");
      } else {
        return ("Signup is closed.");
      }
    } else {
      return ("");
    }
  }

  const StyledDate = () => {
    if (event) {
      return (
        event.days.length > 1 ? 
          DisplayDates(event.days.map(day => day.datetime.toDate()), event.timezone) :
          DisplayDate(event.days[0].datetime.toDate(), event.timezone)
      )
    } else {
      return ("");
    }
  }

  const StyledLocation = (): string => {
    if (event.isGolf) {
      if ((event.days.length > 1) && (!event.days.every( (val, i, arr) => val.courseId === arr[0].courseId))) {
        return "Multiple Courses";
      } else {
        if (isCoursesLoading) {
          return "Loading...";
        } else if (coursesError) {
          return "Error loading courses";
        } else if (courses) {
          return courses.find(c => c.itemId === event.days[0].courseId)?.name ?? "";
        } else {
          return "";
        }
      }
    } else {
      return event.socialLocation;
    }
  }

  const StyledTime = (): string[] => {
    if (event) {
      if (event.isGolf) {  
        return event.days.flatMap((day: EventDay) => {
          const course = courses ? courses.find(c => c.itemId === day.courseId) : undefined;
          const locationName = course ? course.shortName : "";
          const tz = course ? course.timezone : "America/Los_Angeles";
          return day.isShotgun ?
            `${locationName}: ${DisplayDate(day.datetime.toDate(), tz, "MMM DD, ")}${DisplayTime(day.datetime, tz)} shotgun start` :
            `${locationName}: ${DisplayDate(day.datetime.toDate(), tz, "MMM DD ")}${DisplayTime(day.datetime, tz)} ${event.numTeeTimes} tee times`;
        });     
      } else {
        return [
          `${DisplayTime(event.days[0].datetime, event.timezone)} - ${DisplayTime(event.days[event.days.length - 1].datetime, event.timezone)}`
        ];
      }
    } else {
      return [];
    }
}

  const ActionBar = (): ReactElement => {
    return (
      <Stack spacing={1}>
        <EventRegBar 
          event={event} 
          isFull={event?.registrationCount ? (event.maxPlayers !== 0 && (event.registrationCount>= event.maxPlayers)) : false}
        />
        {(isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId, event.orgId)) && (
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={() => navigate(`/event-edit/${event.orgId}/${event.itemId}`)}
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => setDeleteConfirm(true)}
            >
              <DeleteIcon />
            </IconButton>
            {deleteConfirm && (
              <span>
                <Button size="small" onClick={() => deleteThisEvent(event)}>
                  Confirm
                </Button>
                <Button size="small" onClick={() => setDeleteConfirm(false)}>
                  Cancel
                </Button>
              </span>
            )}
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <GratiCard 
      to={isClickable ? `/event/${event.orgId}/${event.itemId}` : undefined} 
      key={event.itemId}
      variant={variant}
      overline={event.leagueName + (event.seasonName ? " (" + event.seasonName + ")" : "")}
      tagline={statusText()}
      avatar={event.logo}
      title={StyledDate()} 
      subtitle={event.name.length > 0 ? " - " + event.name : undefined}
      location={StyledLocation()}
      description={event.description}
      footer={StyledTime()}
      actionbar={<ActionBar />}
      isTest={event.isTest}
    />
  );
}