import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import { styled } from '@mui/material/styles';

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Papa from "papaparse";

import { useAuth } from "../../contextProviders/AuthProvider";
import { useData } from "../../contextProviders/DataProvider";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type GHINData = {
  email: string
  ghin: string
  index: string
}

type GHINValues = {
  data: GHINData[]
}

export default function PersonGhinCsvUpdate(): ReactElement {
  const { isGod } = useAuth();
  const { updateGHINIndex } = useData();

  const [golfGeniusCSVFile, setGolfGeniusCSVFIle] = useState<string>(""); 
  const [ghinValues, setGhinValues] = useState<GHINValues>({ data: [] });

  useEffect(() => {
    if (golfGeniusCSVFile !== "") {
      Papa.parse(golfGeniusCSVFile, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (results: GHINValues) => {
          setGhinValues(results);
        }
      });
    }
  }, [golfGeniusCSVFile]);

  useEffect(() => {
    ghinValues.data.forEach((ghinValue) => {
      if ((ghinValue.ghin && (ghinValue.ghin !== "")) && (ghinValue.index && (ghinValue.index !== ""))) {
        // update the user's index in the database
        updateGHINIndex(ghinValue.ghin, ghinValue.index).catch (error => {
          console.log(error, "ghin: " + ghinValue.ghin + " index: " + ghinValue.index);
        });
      }
    });
  }, [ghinValues, updateGHINIndex]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target?.result;
        if (typeof contents === "string") {
          setGolfGeniusCSVFIle(contents);
        }
      };
      reader.readAsText(file);
    }
  };

  if (isGod) {
    return (
      (<Grid container justifyContent="center">
        <Grid size={4}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}>
          Update Indexes from GHIN CSV
          <VisuallyHiddenInput 
            type="file" 
            accept=".csv"
            onChange={handleFileUpload}       
          />      
        </Button>
        </Grid>
      </Grid>)
    );
  } else {
    return <></>;
  }
}