import { collection, doc, DocumentReference } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';

import type Person from '../types/Person';

export default function usePerson(personId: string | undefined) {
  const { userProfile } = useData();

  const [person, isPersonLoading, personError] = useDocumentData<Person>(
    personId && userProfile ? 
      doc(collection(firestore, 'profile'), personId) as DocumentReference<Person> :
      null);

  return { person, isPersonLoading, personError };
}
