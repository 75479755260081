import { 
  ReactElement,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useData } from "../../contextProviders/DataProvider";
import { OrgMembershipStatus } from "../../enums/OrgMembershipStatus";
import Org from "../../types/Org";
import PersonDirectory from "../person/PersonDirectory";

import OrgApplications from "./OrgApplications";
import OrgCard from "./OrgCard";
import { OrgMemberDirectory } from "./OrgMemberDirectory";

type OrgViewProps = {
  org: Org | undefined;
  initialTab?: string;
};

export default function OrgView(props: OrgViewProps ): ReactElement {
  const { org, initialTab } = props;
  const navigate = useNavigate();
  const { isOrgAdmin } = useData();

  console.log('OrgDetails render', {
    orgId: org?.itemId,
    isOrgAdmin: isOrgAdmin(org ? org.itemId : ''),
    time: new Date().toISOString()
  });

  const [tabSelected, setTabSelected] = useState(initialTab ? initialTab : "1");

  const handleTabChange = (event: React.SyntheticEvent, newTabSelected: string) => {
    setTabSelected(newTabSelected);
    navigate(newTabSelected === '2' ? '/org-applications/' + org?.itemId : '/org/' + org?.itemId, { 
      replace: true,
      state: { preventReload: true} 
    });
  };

  const OrgMemberManager = (): ReactElement | null => {
    if (!org) return null;
  
    return (
      <Box sx={{width: "100%"}}>
        <TabContext value={tabSelected}>
          <Box>
            <TabList 
              onChange={handleTabChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            > 
              <Tab label="Members" value="1" />
              <Tab label="Applications" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <OrgMemberDirectory 
              org={org} 
              orgMembershipStatus={OrgMembershipStatus.ALL}
            />
          </TabPanel>
          <TabPanel value="2">
            <OrgApplications org={org} />
          </TabPanel>
        </TabContext>
      </Box>
    );
  };

  const OrgView = (): ReactElement => {
    if (org) {
      return (
        <Stack spacing={2}>
          <OrgCard org={org} variant="none" isClickable={false} />
          <Divider sx={{ display: { sm: 'none' } }} />
          {isOrgAdmin(org.itemId) ?
            <OrgMemberManager /> :
            <PersonDirectory 
              pageTitle="Members"
              orgMembershipStatus={OrgMembershipStatus.ACTIVE} 
              orgId={org.itemId} 
            />
          }
        </Stack>
      );
    } else {
      return <></>;
    }
  }; 

  return (
    <>
      <OrgView />
    </>
  );
}