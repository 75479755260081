import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import SportsGolfIcon from '@mui/icons-material/GolfCourse';

import { keyframes } from '@mui/system';

const spinY = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
`;

export default function Hourglass(): ReactElement {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      sx={{ margin: 3 }}
    >
      <CircularProgress 
        size="3rem"
        sx={{
          color: 'primary.main',
          position: 'absolute',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          perspective: '1000px', // Adds 3D depth perception
          transformStyle: 'preserve-3d',
        }}
      >
        <SportsGolfIcon
          sx={{
            animation: `${spinY} 1.5s linear infinite`,
            color: 'primary.main',
            fontSize: 24,
          }}
        />
      </Box>
    </Box>
  );
}
    