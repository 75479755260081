import { 
  ReactElement, 
  useEffect, 
} from 'react';

import Box from '@mui/material/Box';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import PersonDirectory from '../components/person/PersonDirectory';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import { OrgMembershipStatus } from '../enums/OrgMembershipStatus';

interface MembersProps {
  initialTab?: string;
}

export default function Members(props: MembersProps): ReactElement {
  const { setPageName } = useApp();
  const { isGod } = useAuth();
  useEffect(() => {
    setPageName('Members');
  }, [setPageName]);

  return (
    <GratiPageWrapper>
      <Box sx={{width: "100%"}}>
        <PersonDirectory 
          orgMembershipStatus={isGod ? OrgMembershipStatus.ALL : OrgMembershipStatus.ALL}
          pageTitle="Directory"
        />
      </Box>
    </GratiPageWrapper>
  );
}