import { memo, ReactElement, useMemo } from 'react';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import useLeaguesWithAdminRights from '../../dataProviders/useLeaguesWithAdminRights';
import League from '../../types/League';
import Hourglass from '../common/Hourglass';

interface LeagueSelectorProps {
  value: string;
  handleChange: (league: League) => void;
  showOrgNames?: boolean;
  disabled?: boolean;
  helperText?: string;
  required?: boolean;
}

export default memo(function LeagueSelector(props: LeagueSelectorProps): ReactElement {
  const { value, handleChange, showOrgNames = false, disabled = false, helperText, required = false } = props;

  const { leagues, isLeaguesLoading, leaguesError } = useLeaguesWithAdminRights();

  const sortedLeagues = useMemo(() => {
    return leagues?.sort((a, b) => {
      // First sort by orgName
      const orgCompare = (a.orgName || '').localeCompare(b.orgName || '');
      if (orgCompare !== 0) return orgCompare;

      // Then by league name within same org
      return (a.name || '').localeCompare(b.name || '');
    });
  }, [leagues]);

  console.log('LeagueSelector render:', {
    value,
    leagues: leagues?.map((l) => ({ id: l.itemId, name: l.name })),
    sortedLeagues: sortedLeagues?.map((l) => ({ id: l.itemId, name: l.name })),
  });

  console.log('LeagueSelector props:', {
    rawValue: value,
    typeofValue: typeof value,
    valueOrEmpty: value || '',
    hasValue: !!value,
  });

  if (isLeaguesLoading) {
    return <Hourglass />;
  }

  if (leaguesError) {
    console.error('Error loading leagues:', leaguesError);
    return <div>Error loading leagues</div>;
  }

  const getDisplayName = (league: League) => {
    return showOrgNames ? `${league.orgName}: ${league.name}` : league.name;
  };

  return (
    <TextField
      select
      required={required}
      fullWidth
      label="League"
      value={value}
      error={required && !value}
      disabled={disabled}
      helperText={helperText || (required && !value ? 'League is required' : '')}
      onChange={(e) => {
        console.log('onChange:', e.target.value);
        const league = leagues?.find((l) => l.itemId === e.target.value) as League | undefined;
        console.log('found league:', league);
        if (league) handleChange(league);
      }}
    >
      {sortedLeagues?.map((league) =>
        league ? (
          <MenuItem key={league.itemId} value={league.itemId}>
            {getDisplayName(league)}
          </MenuItem>
        ) : null
      )}
    </TextField>
  );
});
