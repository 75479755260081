import { 
  ReactElement, 
  useEffect, 
  useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import EventSchedule from '../components/event/EventSchedule';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';

interface EventsProps {
  initialTab?: string;
}

export default function Events(props: EventsProps): ReactElement {
  const { canCreateEvents } = useData();
  const navigate = useNavigate();
  const { setPageName } = useApp();
  const [tabSelected, setValue] = useState(props.initialTab ? props.initialTab : '1');

  useEffect(() => {
    setPageName('Events');
  }, [setPageName]);

  const handleChange = (event: React.SyntheticEvent, newTabSelected: string) => {
    setValue(newTabSelected);
    switch (newTabSelected) {
      case '1':
        navigate('/upcoming-events', { 
          replace: true,
          state: { preventReload: true} 
        });
        break;
      case '2':
        navigate('/past-events', { 
          replace: true,
          state: { preventReload: true} 
        });
        break;
    }
  };

  return (
    <GratiPageWrapper>
      <Box sx={{width: "100%"}}>
        <TabContext value={tabSelected}>
          <Box>
            <TabList
              onChange={handleChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab value="1" label="Upcoming" wrapped />
              <Tab value="2" label="Past" wrapped />
            </TabList>
          </Box>
          <TabPanel value="1">
            <>
              <Container disableGutters className="SectionContainer">
                <Typography variant="headline">Upcoming Events</Typography>
                <EventSchedule isUpcoming={true} />
              </Container>
              {canCreateEvents() && (
                <Fab
                  variant="extended"
                  color="primary"
                  aria-label="add event"
                  component={Link}
                  to="/event-create"
                >
                  <AddIcon />
                  Add event
                </Fab>
              )}
            </>
          </TabPanel>
          {tabSelected === '2' && (
            <TabPanel value="2">
              <Container disableGutters className="SectionContainer">
                <Typography variant="headline">Past Events</Typography>
                <EventSchedule isUpcoming={false} isSortedbyLeague={true} />
              </Container>
            </TabPanel>
          )}
        </TabContext>
      </Box>
    </GratiPageWrapper>
  );
}
