import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import { styled } from '@mui/material/styles';

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Papa from "papaparse";

import { useAuth } from "../../contextProviders/AuthProvider";
import { useData } from "../../contextProviders/DataProvider";
import { 
  CompHandicap, 
  CompScoring,
} from "../../types/Competition";
import Score from "../../types/Score";
import Season from "../../types/Season";
import { generateFirestoreId } from "../../utils/utils";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type Scores = {
  data: Score[]
}

export type SeasonStandingCsvUploadProps = {
  season: Season;
}

export default function SeasonStandingCsvUpload(props: SeasonStandingCsvUploadProps) : ReactElement {
  const { season } = props;
  const { isGod } = useAuth();
  const { addStandingToSeason } = useData();

  const [csvFile, setCsvFile] = useState<string>(""); 
  const [scores, setScores] = useState<Scores>({ data: [] });

  useEffect(() => {
    if (csvFile !== "") {
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (results: Scores) => {
          setScores(results);
        }
      });
    }
  }, [csvFile]);

  useEffect(() => {
    if (scores.data.length > 0) {
      const standing = {
        competition: {
          name: "Meadows Eclectic - Net",
          scoring: CompScoring.Eclectic,
          handicap: CompHandicap.Net,
          itemId: generateFirestoreId()
        },
        courseId: "1yeuzwBqMruA0QK4aDLc",
        courseName: "Meadows Golf Course",
        name: "Meadows Eclectic - Net",
        scores: scores.data
      }
      addStandingToSeason(season, standing).catch (error => {
        console.log(error, "playerId: " + standing.name);
      });
    }

  }, [season, scores, addStandingToSeason]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target?.result;
        if (typeof contents === "string") {
          setCsvFile(contents);
        }
      };
      reader.readAsText(file);
    }
  };

  if (isGod) {
    return (
      (<Grid container justifyContent="center">
        <Grid size={4}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}>
          Upload Standings for a Season
          <VisuallyHiddenInput 
            type="file" 
            accept=".csv"
            onChange={handleFileUpload}       
          />      
        </Button>
        </Grid>
      </Grid>)
    );
  } else {
    return <></>;
  }
}