import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { 
  getDownloadURL, 
  ref 
} from "firebase/storage";

import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useApp } from "../contextProviders/AppProvider";
import { useAuth } from "../contextProviders/AuthProvider";
import { imagesRef } from "../firebase";
//import NotVerifiedMsg from "../components/NotVerifiedMsg";

export default function Welcome(): ReactElement {
  const welcomeImageFileName = "welcome.png";
  const [welcomeImageUrl, setWelcomeImageUrl] = useState("");

  const {
    isLoggedIn,
  } = useAuth();
  const { setPageName } = useApp();

  useEffect(() => {
    setPageName('Welcome');
  }, [setPageName]);

  useEffect(() => {
    getDownloadURL(ref(imagesRef, welcomeImageFileName)).then((url) => {
        setWelcomeImageUrl(url);
      }).catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Stack spacing={2} sx={{display: 'flex' , flexDirection: 'column', alignItems: 'center'}}>
      <Card sx={{ maxWidth: 640 }}> 
        <CardMedia component="img" image={welcomeImageUrl} alt="Welcome" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h5">
            Welcome to the SWGA!
          </Typography>
          <Typography variant="body2" color="surface.contrastText">
            The Sunriver Women&apos;s Golf Association (SWGA) is based at the Sunriver Resort in Central Oregon.
          </Typography>
          <Typography variant="body2" color="surface.contrastText">
            If you live in central Oregon and are interested in golfing or finding out if golf is right for you,
            the SWGA is a great place to start. 
            We have low key events, weekly Wednesday play, team play, senior play, visitations and social events! 
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained" href="/Apply">Apply today</Button>
        </CardActions>
      </Card>
      {!isLoggedIn &&
        <Stack direction="row" spacing={2} sx={{display: 'flex' , flexDirection: 'row', alignItems: 'center'}}>
          <Typography variant="body2" color="surface.contrastText" align="center">
            Already a member? 
          </Typography>
          <Button variant="contained" color="secondary" component={Link} to="/login">
            Login
          </Button>
        </Stack>
      }
    </Stack>
  );
}