import { useMemo } from 'react';

import { doc } from 'firebase/firestore';
import { DocumentReference } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { firestore } from '../firebase';
import Person from '../types/Person';

import type PersonDetails from '../types/PersonDetails';

export default function usePersonDetails(person: Person | undefined) {
  const docRef = useMemo(
    () =>
      person
        ? (doc(firestore, 'profile', person.itemId, 'details', person.itemId) as DocumentReference<PersonDetails>)
        : null,
    [person]
  )

  const [personDetails, isPersonDetailsLoading, personDetailsError] = useDocumentData<PersonDetails>(docRef);

  return { personDetails, isPersonDetailsLoading, personDetailsError };
}
