import { 
  Route, 
  Routes,
  useNavigate,
} from "react-router-dom";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import type {} from '@mui/material/themeCssVarsAugmentation';

import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';

import dayjs from 'dayjs';

import { Button, Divider, responsiveFontSizes, Typography } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import GratiAppBar from "./components/common/GratiAppBar";
import GratiNavRail from "./components/common/GratiNavRail";
import Hourglass from "./components/common/Hourglass";
import PrivateRoute from "./components/common/PrivateRoute";
import { useApp } from "./contextProviders/AppProvider";
import { useAuth } from "./contextProviders/AuthProvider";
import { useData } from "./contextProviders/DataProvider";
import { MapsProvider } from "./contextProviders/MapsProvider";
import AcceptOrgInvitation from "./pages/AcceptOrgInvitation";
import Apply from "./pages/Apply";
import CoursePage from "./pages/CoursePage";
import Courses from "./pages/Courses";
import EmailVerified from "./pages/EmailVerified";
import EventCreatePage from "./pages/EventCreatePage";
import EventPage from "./pages/EventPage";
import Events from "./pages/Events";
import FirestoreDocUpdater from "./pages/FirestoreDocUpdater";
import ForgotPassword from "./pages/ForgotPassword";
import Guests from "./pages/Guests";
import Handbook from "./pages/Handbook";
import Home from "./pages/Home";
import InviteMembers from "./pages/InviteMembers";
import LeagueCreatePage from "./pages/LeagueCreatePage";
import LeaguePage from "./pages/LeaguePage";
import Leagues from "./pages/Leagues";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Members from "./pages/Members";
import OrgPage from "./pages/OrgPage";
import "./styles/App.css";
import Orgs from "./pages/Orgs";
import PersonPage from "./pages/PersonPage";
import ResetPassword from "./pages/ResetPassword";
import Verify from "./pages/Verify";
import Welcome from "./pages/Welcome";
import { APP_BAR_HEIGHT } from './styles/mui6Theme';
import mui6Theme from "./styles/mui6Theme";
import { useOnlineStatus } from "./utils/useOnlineStatus";

function App() {
  // useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light'
  const navRailWidth = '5rem';
  const navigate = useNavigate();
  const { isEmulator, isOnline } = useOnlineStatus();
  const { isHomePage } = useApp();
  const { isGod, isLoggedIn, isLoggingIn, isRevealingGodsSecrets, revealGodsSecrets } = useAuth();
  const { isAdminDisabled, adminDisabled, isUserLoading } = useData();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const responsiveMui6Theme = responsiveFontSizes(mui6Theme);
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

  return (
    <MapsProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={responsiveMui6Theme} defaultMode="system">
          <Box sx={{ display: "flex", flexGrow: 1}}>
            <GratiAppBar isTopLevelPage={!isLoggedIn || isHomePage} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1}}/>
            {isLoggedIn && 
              <GratiNavRail sx={{display: {xs: "none", md: "flex"}}}/>
            }
            {isLoggingIn || isUserLoading ? 
              ( <Box sx={{ 
                display: 'flex', 
                flexGrow: 1, 
                justifyContent: 'center', 
                alignItems: 'center',
                mt: APP_BAR_HEIGHT
              }}>
                <Hourglass />
              </Box>
            ) : (
              <Stack 
                position="sticky" 
                className="AppStack" 
                sx={{ display: "flex", flexGrow: 1, spacing: 1, justifyContent: "flex-start", width: {xs: '100vw', md: `calc(100vw - ${navRailWidth})`}, backgroundColor: "surface.main",}}>
                <Offset />
                <Box sx={{p: 1}}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/accept-org-invitation/:orgName?/:email?/:name?" element={<AcceptOrgInvitation />} />
                  <Route path="/course/:courseId" element={<PrivateRoute><CoursePage /></PrivateRoute>} />
                  <Route path="/course-create" element={<PrivateRoute><CoursePage new={true} edit={true}/></PrivateRoute>} />
                  <Route path="/courses" element={<PrivateRoute><Courses /></PrivateRoute>} />
                  <Route path="/course-edit/:courseId" element={<PrivateRoute><CoursePage edit={true}/></PrivateRoute>} />
                  <Route path="/upcoming-events" element={<PrivateRoute><Events initialTab={"1"}/></PrivateRoute>} />
                  <Route path="/past-events" element={<PrivateRoute><Events initialTab={"2"} /></PrivateRoute>} />
                  <Route path="/emailverified" element={<EmailVerified />} />
                  <Route path="/event/:orgId/:eventId" element={<PrivateRoute><EventPage/></PrivateRoute>} />
                  <Route path="/event-create/:orgId?/:leagueId?/:seasonId?" element={<PrivateRoute><EventCreatePage /></PrivateRoute>} />
                  <Route path="/event-edit/:orgId/:eventId" element={<PrivateRoute><EventPage isEditMode={true}/></PrivateRoute>} />
                  <Route path="/attendees-edit" element={<PrivateRoute><EventPage/></PrivateRoute>} />
                  <Route path="/events" element={<PrivateRoute><Events /></PrivateRoute>} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="/guests" element={<PrivateRoute><Guests /></PrivateRoute>} />
                  <Route path="/handbook" element={<PrivateRoute><Handbook /></PrivateRoute>} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/apply" element={<Apply />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/league/:orgId/:leagueId" element={<PrivateRoute><LeaguePage isEditMode={false}/></PrivateRoute>} />
                  <Route path="/league-create/:orgId" element={<PrivateRoute><LeagueCreatePage /></PrivateRoute>} />
                  <Route path="/league-edit/:orgId/:leagueId" element={<PrivateRoute><LeaguePage isEditMode={true} /></PrivateRoute>} />
                  <Route path="/leagues" element={<PrivateRoute><Leagues /></PrivateRoute>} />
                  <Route path="/members" element={<PrivateRoute><Members initialTab={"1"}/></PrivateRoute>} />
                  <Route path="/orgs" element={<PrivateRoute><Orgs /></PrivateRoute>} />
                  <Route path="/org/:orgId" element={<PrivateRoute><OrgPage edit={false} initialTab={"1"}/></PrivateRoute>} />
                  <Route path="/org-applications/:orgId" element={<PrivateRoute><OrgPage edit={false} initialTab={"2"}/></PrivateRoute>} />
                  <Route path="/org-create" element={<PrivateRoute><OrgPage new={true} edit={true} /></PrivateRoute>} />
                  <Route path="/org-edit/:orgId" element={<PrivateRoute><OrgPage edit={true} initialTab={"1"}/></PrivateRoute>} />
                  <Route path="/invite-members/:orgId" element={<PrivateRoute><InviteMembers /></PrivateRoute>} />
                  <Route path="password-reset" element={<ResetPassword />} />
                  <Route path="/profile" element={<PrivateRoute><PersonPage isEditMode={false} /></PrivateRoute>} />
                  <Route path="/profile/:personId" element={<PrivateRoute><PersonPage isEditMode={false} /></PrivateRoute>} />
                  <Route path="/profile-edit/:personId" element={<PrivateRoute><PersonPage isEditMode={true} /></PrivateRoute>} />
                  <Route path="/verify" element={<Verify />} />
                  <Route path="/welcome" element={<Welcome />} />
                  {/* God only routes */}
                  <Route path="/firestore-doc-updater" element={<PrivateRoute><FirestoreDocUpdater /></PrivateRoute>} />
                </Routes>
                {(!isOnline || isEmulator) &&
                  <Stack 
                    direction="row"
                    spacing={1}
                    divider={<Divider orientation="vertical" flexItem />}
                    sx={{
                      display: 'flex', 
                      flexGrow: 1, 
                      px: 2,
                      backgroundColor: "surface.main", 
                      color: "surface.contrastText"}}
                  >
                    {isOnline ? 
                      <SignalWifi4BarIcon sx={{color: "success.main"}}/> : 
                      <MobiledataOffIcon sx={{color: "error.main"}} /> 
                    }
                    <Typography variant="body1" component="span">
                      {isOnline ? "Online" : "Offline"}
                    </Typography>
                    {isEmulator ? 
                      <DeveloperModeIcon sx={{color: "success.main"}}/> : 
                      <ImportExportIcon sx={{color: "warning.main"}} /> 
                    }
                    <Typography variant="body1" component="span">
                      {isEmulator ? "Emulator" : "Production"}
                    </Typography>
                  </Stack>
                }
                  {isGod && (
                    <Stack
                      sx={{
                        display: 'flex',
                        flexGrow: 0,
                        spacing: 1,
                        backgroundColor: 'tertiary.container',
                        color: 'tertiary.onContainer',
                      }}
                    >
                      <Stack direction="row" spacing={2} sx={{p: 1}}>
                        <Button 
                          variant="outlined"
                          size="small" 
                          type="button" 
                          onClick={() => navigate('/firestore-doc-updater')}
                        >
                          Firestore Editor
                        </Button>
                      </Stack>
                      <FormGroup sx={{ px: 1 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id={'enable_god'}
                              checked={isRevealingGodsSecrets}
                              onChange={(e) => {
                                revealGodsSecrets(e.target.checked);
                              }}
                            />
                          }
                          label="Reveal God's Secrets"
                        />
                      </FormGroup>
                      <FormGroup sx={{ px: 1 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id={'disable_admin'}
                              checked={isAdminDisabled}
                              onChange={(e) => {
                                adminDisabled(e.target.checked);
                              }}
                            />
                          }
                          label={'Disable Admin'}
                        />
                      </FormGroup>
                    </Stack>
                  )}
                </Box>
              </Stack>
            )}
          </Box>

        </ThemeProvider>
      </LocalizationProvider>
    </MapsProvider>
  );
}

export default App;