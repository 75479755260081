import { ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { Link, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import LeagueEditor from '../components/league/LeagueEditor';
import LeagueView from '../components/league/LeagueView';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import useLeague from '../dataProviders/useLeague';

interface LeaguePageProps {
  isEditMode?: boolean;
}

export default function LeaguePage(props: LeaguePageProps): ReactElement {
  const { isEditMode } = props;

  const { updateActions, setPageName } = useApp();
  const { isLeagueAdmin } = useData();
  const { orgId, leagueId } = useParams();
  const navigate = useNavigate();
  const { league, isLeagueLoading, leagueError } = useLeague({ orgId, leagueId });

  useEffect(() => {
    const handleEditClick = () => {
      if (league) {
        navigate('/league-edit/' + league.orgId + '/' + league.itemId, { replace: true });
      }
    };

    if (league) {
      setPageName(league.name);
      if (isLeagueAdmin(league.itemId, league.orgId)) {
        updateActions([
          {
            name: 'Edit',
            handleClick: handleEditClick,
            icon: EditIcon,
          },
        ]);
      }
    } else if (leagueError) {
      setPageName(leagueError.message);
    }
  }, [league, leagueError, isLeagueAdmin, updateActions, setPageName, navigate]);

  console.log('league', league);
  return (
    <GratiPageWrapper isContentLoading={isLeagueLoading && !league}>
      <>
        {league && (
          <>
            {isLeagueAdmin(league.itemId, league.orgId) ? (
              <Box>
                {isEditMode ? 
                  <LeagueEditor league={league} isNewLeague={false} /> : 
                  <LeagueView league={league} />
                }
                <Fab
                  variant="extended"
                  color="primary"
                  aria-label="add event"
                  component={Link}
                  to={`/event-create/${league.orgId}/${league.itemId}`}
                >
                  <AddIcon />
                  Add event
                </Fab>
              </Box>
            ) : (
              <Box>
                <LeagueView league={league} />
              </Box>
            )}
          </>
        )}
      </>
    </GratiPageWrapper>
  );
}