import { useEffect, ReactElement, useState } from "react";

import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import LoginIcon from "@mui/icons-material/Login";
import PasswordIcon from '@mui/icons-material/Password';

import GratiPageWrapper from "../components/common/GratiPageWrapper";
import { useApp } from "../contextProviders/AppProvider";
import { useAuth } from "../contextProviders/AuthProvider";

export default function Login(): ReactElement {
  const navigate = useNavigate();
  const { setPageName } = useApp();
  const { auth, isLoggedIn } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [ signInWithEmailAndPassword, loggedInUser, isLoginLoading, loginError ] = useSignInWithEmailAndPassword(auth);

  useEffect(() => {
    setPageName("Login to GratiGolf");
  }, [setPageName]);

  useEffect(() => {
    if (!isLoginLoading) {
      if (loggedInUser) {
        /* isAuthenticated ? navigate("/") : navigate("/verify", { state: {isEmailSentFirst: false }}); */
        navigate("/");
      }
    }
  }, [isLoginLoading, loggedInUser, navigate]);

  const handleSubmit = async () => {
    try {
      await signInWithEmailAndPassword(email, password);
    } catch (e) {
      console.log(e)
    }
  };

  const GenerateErrorMessage = () => {
    if (loginError) {
      if (loginError.code === "auth/user-not-found") {
        return (
          <Alert variant="filled" severity="error">
            Your email address appears to be incorrect.
          </Alert>);
      }
      if (loginError.code === "auth/wrong-password") {
        return (
          <Alert variant="filled" severity="error">
            The password is incorrect. Please retry.
          </Alert>);
      }
      if (loginError.code === "auth/internal-error") {
        return (
          <Alert variant="filled" severity="error">
            Inactive account. Contact your administrator.
          </Alert>);
      }
    } else {
      return null;    
    }
  }

  return (
    <GratiPageWrapper isNoAuthRequired={true}>
      <Container maxWidth="md">
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack spacing={2} sx={{display: 'flex' , flexDirection: 'column', alignItems: 'center'}}>
            <TextField
              fullWidth
              required
              autoFocus={true}
              autoComplete={"true"}
              id="email"
              label="email"
              aria-label="email"
              helperText="Enter your email..."
              value={email}
              sx={{ minWidth: 300 }}
              onChange={({ target }) => setEmail(target.value)}
            />
            <TextField
              fullWidth
              required
              autoComplete={"current-password"}
              id="password"
              type="password"
              label="password"
              aria-label="password"
              helperText="Enter your password..."
              value={password}
              sx={{ minWidth: 300 }}
              onChange={({ target }) => setPassword(target.value)}
            />
            <Button type="submit" variant="contained" startIcon={<LoginIcon />}>
              Login
            </Button>
          </Stack>
        </Box>
        {loginError && 
          GenerateErrorMessage()}
        {isLoginLoading && 
          <Alert variant="filled" severity="info">
            Logging in...
          </Alert>}
        <Box textAlign="center">
          <Button component={Link} to="/forgot-password" variant="text" color="secondary" startIcon={<PasswordIcon/>}>
            Forgot Password?
          </Button>
        </Box>
        {!isLoggedIn &&
          <Stack direction="row" spacing={2} sx={{display: 'flex' , flexDirection: 'row', alignItems: 'center'}}>
            <Typography variant="body2" color="surface.contrastText">
              Don&apos;t have an account? Tap{' '}
              <Link to="/apply" style={{ color: 'inherit', textDecoration: 'underline' }}>Apply</Link>
              {' '}today!
            </Typography>
          </Stack>
        }
      </Container>
    </GratiPageWrapper>
  );
}
