import { 
  ReactElement, 
  useState 
} from "react";

import Tab from '@mui/material/Tab';

import TabContext from "@mui/lab/TabContext/TabContext";
import TabList from "@mui/lab/TabList/TabList";
import TabPanel from "@mui/lab/TabPanel/TabPanel";

import GratiPageWrapper from "../components/common/GratiPageWrapper";
import { Constitution } from "../components/handbook/Constitution";
import { Tournaments } from "../components/handbook/Tournaments";
import { WeeklyPlay } from "../components/handbook/WeeklyPlay";

export default function Handbook(): ReactElement {
  const [tabSelected, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newTabSelected: number) => {
    setValue(newTabSelected);
  };

  return (
    <GratiPageWrapper>
      <TabContext value={tabSelected.toString()}>
        <TabList
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab value="0" label="Tournaments" wrapped />
          <Tab value="1" label="Weekly Competition Guidelines" wrapped />
          <Tab value="2" label="Constitution and Bylaws" wrapped />
        </TabList>
        <TabPanel value="0">
          <Tournaments />
        </TabPanel>
        <TabPanel value="1">
          <WeeklyPlay />
        </TabPanel>
        <TabPanel value="2">
          <Constitution />
        </TabPanel>
      </TabContext>
    </GratiPageWrapper>
  );
}

/* 
  <Tab label="Weekly Schedule" wrapped />
  <Tab label="Team Play"  wrapped />
  <Tab label="COSWGA"  wrapped />
  <Tab label="Visitations"  wrapped />
  <Tab label="Meetings"  wrapped />
  <Tab label="Past Champions" wrapped />
  <Tab label="Past Presidents" wrapped />
*/