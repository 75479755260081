import { collection, DocumentReference } from 'firebase/firestore';
import { doc } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';

import type Org from '../types/Org';

export default function useOrg(orgId: string | undefined) {
  const { userProfile } = useData();
  const [org, isOrgLoading, orgError] = 
    useDocumentData<Org>(
      orgId && userProfile ? 
        doc(collection(firestore, 'org'), orgId) as DocumentReference<Org> :
        null);

  return { org, isOrgLoading, orgError };
}