import { collection, orderBy, Query } from "firebase/firestore";
import { query, where } from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";
import Application from "../types/Application";

interface UseApplicationsProps {
  orgIds?: string[];
}
export default function useApplications(props: UseApplicationsProps) {
  const { userProfile } = useData();
  const orgIds = 
    props.orgIds ? 
      userProfile?.orgIds?.filter(orgId => props.orgIds?.includes(orgId)) : 
      userProfile?.orgIds;

  // *************** Application loaders ****************
  const qApps = 
    orgIds && orgIds.length > 0 ?
      query(
        collection(firestore, 'application'), 
        where('orgId', 'in', orgIds), 
        orderBy('name')) as Query<Application> :
      query(
        collection(firestore, 'application'), 
        orderBy('name')) as Query<Application>;

  const [apps, isAppsLoading, appsError] = useCollectionData<Application>(qApps);

  return { apps, isAppsLoading, appsError };   
}
