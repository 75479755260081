import { Timestamp } from "firebase/firestore";

import dayjs, { Dayjs } from "dayjs";

import Event from "../types/Event";

export function generateFirestoreId(): string {
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  
  let autoId = ''
  
  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(
      Math.floor(Math.random() * CHARS.length)
    );
  }
  return autoId;
}

// determine if a timestamp.now() is between 11:00pm and 12:00am 
// if so, return true, else return false
export function IsEndOfDay(timestamp: Timestamp, timezone?: string): boolean {
  const tz = timezone ? timezone : "America/Los_Angeles";
  const date = new Date(timestamp.seconds * 1000);
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: tz,
  };
  const timeString = date.toLocaleTimeString('en-US', options);
  const [hour, minute] = timeString.split(':').map(Number);
  
  return hour === 23 && minute >= 0;
};

export function GetFirstAndLastName(fullName: string): [firstName: string, lastName: string] {
  const regex = /\w+\s\w+(?=\s)|\w+/g;

  if (fullName) {
    const matches = fullName.trim().match(regex);
    if (matches) {
      return [matches[0], matches[1]];
    } else {
      return [fullName, ""]
    }
  } else {
    return [fullName, ""];
  }
}

export function GetFirstName (fullName: string): string {
  return GetFirstAndLastName(fullName)[0];
}

export function FormatPhoneNumber(phoneNumberString: string) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return "";
}

export function IsEventUpcoming(event: Event) {
  const currentDateTime = CurrentDateTime();
  return currentDateTime < event.days[0].datetime;
}

export function IsEventInProgress(event: Event) {
  const currentDateTime = CurrentDateTime();
  return currentDateTime > event.days[0].datetime;
}

export function IsEventOver(event: Event) {
  const currentDateTime = CurrentDateTime();
  const lastDay = event.days[event.days.length - 1];
  const eventEndTime = Timestamp.fromMillis(
    lastDay.datetime.toMillis() + (lastDay.duration * 60 * 1000) // convert minutes to milliseconds
  );
  return currentDateTime > eventEndTime;
}

export function IsEventSignupInProgress(event: Event) {
  const currentDateTime = CurrentDateTime();
  return (
    currentDateTime > event.signupOpensAt && 
    currentDateTime < event.signupClosesAt
  );
}

export function IsEventSignupOver(event: Event) {
  const currentDateTime = CurrentDateTime();
  return currentDateTime > event.signupClosesAt;
}

// And we could add for completeness:
export function IsEventSignupUpcoming(event: Event) {
  const currentDateTime = CurrentDateTime();
  return currentDateTime < event.signupOpensAt;
}

export function DisplayDate(date: Date, tz: string, format?: string): string {
  dayjs.tz.setDefault(tz ? tz : "America/Los_Angeles");

  return dayjs.tz(date.toLocaleString("default", { timeZone: tz }), tz).format(format ? format : 'MMM D, YYYY');
  // timezone is number of hours relative to UTC. SO PST would be -8
  //return new Date(/*(*/datetime.seconds /*+ (timezone * 60 * 60))*/ * 1000)
  //  .toLocaleDateString('en-US');
}

export function DisplayDates(dates: Date[], tz: string, format?: string, isTwoLine?: boolean): string {
  const sortedDates = dates.sort((a, b) => a.valueOf()- b.valueOf());
  const startDate = sortedDates[0];
  const endDate = sortedDates[sortedDates.length-1];

  if (dates.length === 0) {
    return "";
  } else if (dates.length === 1) {
    return DisplayDate(startDate, tz, format);
  } else {
    if (startDate.getFullYear() === endDate.getFullYear()) {
      if (startDate.getMonth() === endDate.getMonth()) {
        return (
          startDate.toLocaleString("default", { month: "short" }) + " " + 
          startDate.getDate().toString() + "-" + 
          endDate.getDate().toString() + ", " + 
          endDate.getFullYear().toString());
      } else {
        if (isTwoLine) {
          return (
            startDate.toLocaleString("default", { month: "short" }) + " " + 
            endDate.toLocaleString("default", { month: "short" }) + "\n" + 
            startDate.getDate().toString() + " " + 
            endDate.getDate().toString() + ", " + 
            endDate.getFullYear().toString());
        } else {
          return (
            startDate.toLocaleString("default", { month: "short" }) + " " + 
            startDate.getDate().toString() + "-" + 
            endDate.toLocaleString("default", { month: "short" }) + " " + 
            endDate.getDate().toString() + ", " + 
            endDate.getFullYear().toString());
        }
      }
    } else {
      // TODO: Handle multi-year events.
      return (
        startDate.toLocaleString("default", { month: "short" }) + " " + 
        startDate.getDate().toString() + ", " + 
        startDate.getFullYear().toString() + " - " + 
        endDate.toLocaleString("default", { month: "short" }) + " " + 
        endDate.getDate().toString() + ", " + 
        endDate.getFullYear().toString());
    } 
    // timezone is number of hours relative to UTC. SO PST would be -8
    //return new Date(/*(*/datetime.seconds /*+ (timezone * 60 * 60))*/ * 1000)
    //  .toLocaleDateString('en-US');    
  }
}

export function CombineDateJSTimeJS(date: Dayjs, time: Dayjs): Timestamp {
  const combined = date.set('hour', time.hour()).set('minute', time.minute());
  return Timestamp.fromDate(combined.toDate());
}

export function DisplayTime(datetime: Timestamp, tz: string, format?: string): string {
  dayjs.tz.setDefault(tz);

  return dayjs.tz(datetime.toDate()).format(format ? format: 'h:mm A'); 
}

export function DisplayDateTime(datetime: Timestamp, tz: string): string {
  return DisplayDate(datetime.toDate(), tz) + " " + DisplayTime(datetime, tz);
}

export function CurrentDateTime(): Timestamp {
  return Timestamp.now();
}

export function CurrentDate(): Timestamp {
  return Timestamp.fromDate(new Date());
}

export function FromDayJS(dayjs: Dayjs | null): Timestamp {
  return (dayjs != null) ? Timestamp.fromDate(dayjs.utc().toDate()) : Timestamp.now();
}

export function ToDayJS(datetime: Timestamp, timezone?: string): Dayjs {

  if ((datetime == null) || (datetime.seconds == null)) {
    datetime = Timestamp.now();
  }

  if (timezone == null) {
    timezone = "America/Los_Angeles";
  } 

  return dayjs.tz(datetime.toDate(), timezone);
}

export function HoursMinutesToMilliseconds(hours: number, minutes: number): number {
  return (hours * 60 * 60 * 1000) + (minutes * 60 * 1000);
}

export function TimezoneList(): {value: number, label: string}[] {
  return [
    {value: -8, label: "PST"},
    {value: -7, label: "PDT"},
    {value: -6, label: "MDT"},
  ];
}