import { and, collection, collectionGroup, orderBy, Query } from "firebase/firestore";
import { query, where } from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";
import League from "../types/League";
import Season from "../types/Season";

interface UseSeasonsProps {
  league?: League;
}

export default function useSeasons(props: UseSeasonsProps) {
  const { league } = props;
  const { isGod } = useAuth();
  const { userProfile } = useData();

  const qLeagueSeasons = league && userProfile && userProfile.orgIds && userProfile.orgIds.length > 0 ? 
    query(
      collection(firestore, "org/" + league.orgId + "/season") as Query<Season>,
      and(
        ...(isGod ? [] : [where("orgId", "in", userProfile.orgIds)]),
        where("leagueId", "==", league.itemId),
      )) : 
    null;

  // *************** Season loaders ****************
  const qSeasons = userProfile && userProfile.orgIds && userProfile.orgIds.length > 0 ? 
    query(
      collectionGroup(firestore, "season") as Query<Season>,
      where("orgId", "in", userProfile.orgIds),
      orderBy("name")) : 
    null;

  const [seasons, isSeasonsLoading, seasonsError] = useCollectionData<Season>(qLeagueSeasons? qLeagueSeasons : qSeasons);

  return { seasons: seasons ?? [], isSeasonsLoading, seasonsError };   
}
