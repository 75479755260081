import { Timestamp } from 'firebase/firestore';

import { HoursMinutesToMilliseconds } from '../utils/utils';

import Event from './Event';
import League from './League';
import PersonDetails from './PersonDetails';

export const DefaultObjects = {
  PersonDetails: {
    itemId: '',
    creatorId: '',
    createTime: Timestamp.now(),
    name: '',
    birthday: Timestamp.now(),
    gender: '',
    localAddress: '',
    mailingAddress: '',
    phone: '',
  } as PersonDetails,
  Event: {
    name: '',
    creatorId: '',
    createTime: Timestamp.now(),
    days: [
      {
        courseId: '',
        datetime: Timestamp.now(),
        duration: HoursMinutesToMilliseconds(4, 0),
        isGolf: true,
        isShotgun: false,
      },
    ],
    datetime: Timestamp.now(),
    description: '',
    isGolf: true,
    isShotgun: false,
    isTest: false,
    itemId: '',
    leagueId: /* leagueId ?? */ '',
    leagueName: '',
    logo: '',
    maxPlayers: 0,
    numTeeTimes: 0,
    orgId: /* orgId ?? */ '',
    seasonId: /* seasonId ?? */ '',
    seasonName: '',
    signupOpensAt: Timestamp.now(),
    signupClosesAt: Timestamp.now(),
    socialLocation: '',
    timezone: 'America/Los_Angeles',
  } as Event,
  League: {
    name: '',
    competitionLevel: 0,
    creatorId: '',
    createTime: Timestamp.now(),
    description: '',
    isTest: false,
    itemId: '',
    logo: '',
    orgName: '',
    orgId: '',
  } as League,
  // Add other types here
};

export type SupportedTypes = keyof typeof DefaultObjects;
export default DefaultObjects;
