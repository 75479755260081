import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';

import {
  and,
  arrayUnion,
  collection,
  collectionGroup,
  CollectionReference,
  deleteDoc,
  deleteField,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FieldValue,
  getDoc,
  getDocs,
  query,
  Query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { deleteObject, ref, uploadBytes } from 'firebase/storage';

import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';

import { firestore } from '../firebase';
import Application from '../types/Application';
import Course from '../types/Course';
import Event from '../types/Event';
import Inquiry from '../types/Inquiry';
import Invitation from '../types/Invitation';
import League from '../types/League';
import Membership from '../types/Membership';
import Org from '../types/Org';
import Person from '../types/Person';
import PersonDetails from '../types/PersonDetails';
import Registration from '../types/Registration';
import Result from '../types/Result';
import Season from '../types/Season';
import { CurrentDateTime } from '../utils/utils';

import { useAuth } from './AuthProvider';

export interface DataProviderProps {
  children?: ReactNode;
}

// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData,>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};

export interface DataContextModel {
  // User Profile
  personsCol: CollectionReference<Person>;
  getPerson: (id: string) => Promise<Person | null>;
  updatePerson: (id: string, profile: Partial<Person>, picFile?: File | null) => Promise<void>;
  updatePersonDetails: (id: string, details: Partial<PersonDetails>) => Promise<void>;
  updateGHINIndex: (ghin: string, index: string) => Promise<void>;

  // User State
  userProfile: Person | undefined;
  isUserActive: boolean;
  isUserLoading: boolean;
  isAdminDisabled: boolean;
  adminDisabled: (disable: boolean) => void;

  // Authorization
  isEventAdmin: (orgId: string, leagueId: string, eventId: string) => boolean;
  isLeagueAdmin: (leagueId: string, orgId: string) => boolean;
  isOrgAdmin: (orgId: string) => boolean;
  isOrgMember: (orgId: string) => boolean;
  canEditPerson: (person: Person) => boolean;
  canEditPersonDetails: (person: Person) => boolean;
  canCreateEvents: () => boolean;
  hasAdminRightsOverPerson: (person: Person) => boolean;
  areInASharedOrg: (resourceProfile: Person, requesterProfile?: Person) => boolean;
  getAdminOrgs: () => string[];
  getMemberOrgs: () => string[];
  adminLeagues: string[];

  // Organization Management
  getOrg: (orgId: string) => Promise<Org | null>;
  addOrg: (org: Org, isAdmin: boolean, isTest?: boolean, isUserProfileUpdated?: boolean) => Promise<Org | null>;
  deleteOrg: (org: Org, isPermanent?: boolean) => Promise<void>;
  updateOrg: (org: Partial<Org>) => Promise<void>;

  // Applications and Inquiries
  applicationsCol: CollectionReference<Application>;
  inquiriesCol: CollectionReference<Inquiry>;
  addInquiry: (name: string, email: string, phone: string) => Promise<void>;
  addApplication: (
    orgId: string,
    name: string,
    mailingAddress: string,
    email: string,
    ghin: string,
    phone: string,
    isApproved?: boolean,
    isTest?: boolean
  ) => Promise<void>;
  deleteApplication: (appId: string) => Promise<void>;
  updateApplication: (appId: string, isApproved: boolean, isRejected: boolean) => Promise<void>;
  addInvitation: (orgId: string, name: string, email: string, isTest?: boolean, personId?: string) => Promise<void>;

  // Membership Management
  memberships: Membership[] | undefined;
  addMembership: (membership: Partial<Membership>) => Promise<Membership | null>;
  updateMembership: (membership: Partial<Membership>) => Promise<void>;
  addOrgMembership: (org: Org, memberId: string) => Promise<Membership | null>;
  acceptOrgMembership: (org: Org, memberId?: string) => Promise<void>;
  deleteOrgMembership: (org: Org, memberId: string, isPermanent?: boolean) => Promise<void>;
  deactivateOrgMembership: (org: Org, memberId: string) => Promise<void>;
  addLeagueMembership: (league: League, memberId: string) => Promise<void>;

  // League Management
  getLeague: (orgId: string, leagueId: string) => Promise<League | null | undefined>;
  addLeague: (league: League, isTest?: boolean) => Promise<League | null>;
  deleteLeague: (league: League) => Promise<void>;
  updateLeague: (league: Partial<League>) => Promise<void>;

  // Season Management
  getSeason: (league: League, seasonId: string) => Promise<Season | null | undefined>;
  addSeason: (season: Partial<Season>) => Promise<Season | null>;
  addStandingToSeason: (season: Season, standing: Result) => Promise<void>;
  deleteSeason: (season: Season) => Promise<void>;
  updateSeason: (season: Partial<Season>) => Promise<void>;

  // Event Management
  getEvent: (orgId: string, eventId: string) => Promise<Event | null>;
  addEvent: (event: Event, isTest?: boolean) => Promise<Event | null>;
  deleteEvent: (event: Event) => Promise<void>;
  updateEvent: (event: Partial<Event>) => Promise<void>;
  addEventRegistration: (
    event: Event,
    isRegistering: boolean,
    personToRegister?: Person
  ) => Promise<Registration | null>;
  updateEventRegistration: (event: Event, playerId: string, registration: Partial<Registration>) => Promise<void>;

  // Course Management
  getCourse: (courseId: string) => Promise<Course | null>;
  addCourse: (course: Course, isTest?: boolean) => Promise<Course | null>;
  deleteCourse: (course: Course) => Promise<void>;
  updateCourse: (course: Partial<Course>) => Promise<void>;

  // Collection Utilities
  removeCollectionField: (docCollection: string, fieldToRemove: string) => Promise<void>;
  updateCollectionField: (docCollection: string, fieldToUpdate: string, value: unknown) => Promise<void>;
  createSubcollectionForAll: <T extends { itemId?: string }>(collectionPath: string, emptyDoc: T) => Promise<number>;
  removeSubcollectionForAll: (collectionPath: string) => Promise<number>;
}

export const DataContext = createContext<DataContextModel>({} as DataContextModel);

export function useData(): DataContextModel {
  return useContext(DataContext);
}

export const DataProvider = ({ children }: DataProviderProps): ReactElement => {
  const { auth, imagesRef, isGod, isLoggedIn, isLoggingIn, user } = useAuth();

  // *************** User Profile ****************
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);
  const personsCol = createCollection<Person>('profile');
  const [userProfile, isUserProfileLoading, userError] = useDocumentData<Person>(
    isLoggedIn && user ? doc(personsCol, user.uid) : null
  );

  // *************** Membership loaders ****************
  console.log('orgIds: ', userProfile?.orgIds);
  console.log('inactive orgIds: ', userProfile?.inactiveOrgIds);
  console.log('userProfile: ', userProfile);
  const qMemberships =
    userProfile && userProfile.orgIds && userProfile.orgIds.length > 0
      ? query(
          collectionGroup(firestore, 'membership') as Query<Membership>,
          and(where('itemId', '==', userProfile.itemId), where('orgId', 'in', userProfile.orgIds))
        )
      : null;
  const [memberships, isMembershipsLoading, membershipsError] = useCollectionData<Membership>(qMemberships);

  const applicationsCol = createCollection<Application>('application');
  const inquiriesCol = createCollection<Inquiry>('inquiry');

  // *************** Admin ****************
  const [isAdminDisabled, setIsAdminDisabled] = useState<boolean>(false);
  const [adminLeagues, setAdminLeagues] = useState<string[]>([]);

  useEffect(() => {
    if (isUserProfileLoading || isLoggingIn || (isLoggedIn && !userProfile && !userError)) {
      setIsUserLoading(true);
      console.log('Loading user profile');
    } else if (userProfile) {
      setIsUserLoading(false);
      console.log('User profile loaded: ' + userProfile.name);
      console.log('userProfile.orgIds: ' + userProfile.orgIds);
    } else if (userError) {
      setIsUserLoading(false);
      console.log('Error loading user profile: ' + userError);
    } else {
      setIsUserLoading(false);
      console.log('User profile unloaded.');
    } // Your existing profile loading logic
  }, [userProfile, isLoggingIn, isLoggedIn, isUserProfileLoading, userError]);

  useEffect(() => {
    if (isMembershipsLoading) {
      console.log('Loading memberships');
      return;
    }

    if (membershipsError) {
      console.log('Error loading memberships:', membershipsError);
      return;
    }

    if (!memberships?.length) {
      return;
    }

    console.log('memberships:', memberships.length);

    // Get active memberships
    const activeMemberships = memberships.filter((m) => m.isActive);

    // Set admin leagues
    const adminLeagues = activeMemberships.filter((m) => m.leagueAdmins).flatMap((m) => m.leagueAdmins || []);
    setAdminLeagues(adminLeagues);
    console.log(adminLeagues.length ? `adminLeagues: ${adminLeagues}` : 'No league admins found.');

    // Debug first membership
    if (memberships[0]) {
      const m = memberships[0];
      console.log(`membership[0]: ${m.orgId}${m.isActive} ${m.isMember}`);
    }
  }, [memberships, isMembershipsLoading, membershipsError]);

  // *************** Helper functions ****************
  function isSelf(profile: Person) {
    if (userProfile?.itemId === profile.itemId) {
      return true;
    }

    return false;
  }
  function isOrgMember(orgId: string): boolean {
    if (!userProfile || !memberships || isAdminDisabled) {
      return false;
    }

    if (isGod || userProfile.orgIds?.includes(orgId)) {
      return true;
    }

    return false;
  }

  // Pending is not included in this check. The reason is that a pendingOrgId is one that is waiting for the user to accept.
  // If we allowed an admin to change their profile, an admin could add any email address they know, then alter their profile.
  // This would allow them to change the profile of any user.
  function hasAdminRightsOverPerson(person: Person) {
    return (
      isGod ||
      isSelf(person) ||
      getAdminOrgs().some((orgId) => person?.orgIds?.includes(orgId) || person?.inactiveOrgIds?.includes(orgId))
    );
  }

  function areInASharedOrg(resourceProfile: Person, requesterProfile: Person | undefined = userProfile): boolean {
    if (isGod || isSelf(resourceProfile)) {
      return true;
    }

    if (!resourceProfile || !requesterProfile) {
      return false;
    }

    // If the requester and resource are in the same org, they share an org!
    if (resourceProfile.orgIds && requesterProfile.orgIds) {
      if (resourceProfile.orgIds?.some((id) => requesterProfile.orgIds?.includes(id))) {
        return true;
      }
    }

    // If the requester is an admin of any of the resource's inactive orgs, they share an org!
    if (resourceProfile.inactiveOrgIds && requesterProfile.adminOrgIds) {
      if (resourceProfile.inactiveOrgIds?.some((id) => requesterProfile.adminOrgIds?.includes(id))) {
        return true;
      }
    }

    return false;
  }

  // *************** Admin ****************
  function isOrgAdmin(orgId: string): boolean {
    if (!userProfile || !memberships || isAdminDisabled) {
      return false;
    }

    if (isGod || userProfile?.adminOrgIds?.find((org) => org === orgId)) {
      return true;
    }

    return false;
  }

  function isLeagueAdmin(leagueId: string, orgId: string): boolean {
    if (!userProfile || !memberships || isAdminDisabled) {
      return false;
    }

    return isGod || isOrgAdmin(orgId) || adminLeagues.includes(leagueId);
  }

  function isEventAdmin(orgId: string, leagueId: string, eventId: string): boolean {
    // placeholder. will add fancier logic for who can edit an event later.
    return isLeagueAdmin(leagueId, orgId);
  }

  function canEditPerson(profile: Person): boolean {
    return isGod || isSelf(profile) || hasAdminRightsOverPerson(profile);
  }

  function canEditPersonDetails(profile: Person): boolean {
    return isGod || isSelf(profile);
  }

  function canCreateEvents(): boolean {
    return Boolean(
      isGod || (userProfile?.adminOrgIds && (userProfile?.adminOrgIds?.length > 0 || adminLeagues.length > 0))
    );
  }

  function getAdminOrgs(): string[] {
    return userProfile?.adminOrgIds || [];
  }

  function getMemberOrgs(): string[] {
    return userProfile?.orgIds || [];
  }

  function adminDisabled(disable: boolean) {
    setIsAdminDisabled(disable);
  }

  // *************** Org functions ****************
  const getOrg = async (orgId: string): Promise<Org | null> => {
    if (!userProfile) {
      console.error('User profile not found for auth id:', auth.currentUser?.uid);
      return null;
    }

    if (!orgId || !userProfile.orgIds?.includes(orgId)) {
      console.error('Permission denied. User id:', userProfile.itemId, 'Org:', orgId);
      return null;
    }

    try {
      console.log('Getting org:', orgId);
      const orgRef = doc(firestore, 'org/' + orgId);
      const org = await getDoc(orgRef);

      if (!org.exists()) {
        console.error('Org not found:', orgId);
        return null;
      }

      return org.data() as Org;
    } catch (error) {
      console.error('Error fetching org:', error);
      return null;
    }
  };

  const addOrg = async (
    org: Org,
    isAdmin: boolean,
    isTest = false,
    isUserProfileUpdated = true
  ): Promise<Org | null> => {
    if (!userProfile) {
      console.error('User profile not found for auth id:', auth.currentUser?.uid);
      return null;
    }

    if (!isGod) {
      console.error('Permission denied. User id:', userProfile.itemId);
      return null;
    }

    if (!org) {
      console.error('No org provided');
      return null;
    }

    try {
      const batch = writeBatch(firestore);

      // Create org
      const orgRef = doc(collection(firestore, 'org'));
      const newOrg = {
        ...org,
        creatorId: userProfile.itemId,
        createTime: CurrentDateTime(),
        itemId: orgRef.id,
        isActive: true,
        isTest: isTest,
      };
      batch.set(orgRef, newOrg);

      if (isUserProfileUpdated) {
        // Add membership to batch
        const membershipRef = doc(firestore, `org/${orgRef.id}/membership/${userProfile.itemId}`);
        batch.set(membershipRef, {
          isActive: true,
          isAdmin: isAdmin,
          isMember: true,
          itemId: userProfile.itemId,
          name: userProfile.name,
          orgId: orgRef.id,
          createTime: CurrentDateTime(),
          creatorId: userProfile.itemId,
        });

        // Update profile in batch
        const profileRef = doc(firestore, 'profile', userProfile.itemId);
        batch.update(profileRef, {
          orgIds: arrayUnion(orgRef.id),
        });
      }

      await batch.commit();
      return newOrg;
    } catch (error) {
      console.error('Error adding org:', error);
      return null;
    }
  };

  const deleteOrg = async (org: Org, isPermanent = false): Promise<void> => {
    if (!userProfile) {
      console.error('User profile not found for auth id:', auth.currentUser?.uid);
      return;
    }

    if (!isGod) {
      console.error('Permission denied. User id:', userProfile.itemId);
      return;
    }

    if (!org.itemId) {
      console.error('Org itemId not found:', org.itemId);
      return;
    }

    try {
      const orgRef = doc(firestore, 'org/' + org.itemId);

      if (isPermanent) {
        await deleteDoc(orgRef);
      } else {
        await updateDoc(orgRef, { ...org, isActive: false });
      }
    } catch (error) {
      console.error('Error deleting org:', error);
    }
  };

  const updateOrg = async (org: Partial<Org>): Promise<void> => {
    if (!userProfile) {
      console.error('User profile not found for auth id:', auth.currentUser?.uid);
      return;
    }

    if (!isGod) {
      console.error('Permission denied. User id:', userProfile.itemId);
      return;
    }

    if (!org.itemId) {
      console.error('Org itemId not found:', org.itemId);
      return;
    }

    try {
      const orgRef = doc(firestore, 'org/' + org.itemId);
      await updateDoc(orgRef, { ...org });
    } catch (error) {
      console.error('Error updating org:', error);
    }
  };

  // *************** Invitation functions ****************
  const addInvitation = async (
    orgId: string,
    name: string,
    email: string,
    isTest = false,
    personId?: string
  ): Promise<void> => {
    if (!userProfile?.itemId) {
      console.error('User profile not found');
      return;
    }

    try {
      // Get org name
      const orgRef = doc(firestore, 'org/' + orgId);
      const orgDoc = await getDoc(orgRef);
      const orgName = orgDoc.data()?.name || '';

      // Create invitation
      const invitationRef = doc(collection(firestore, `org/${orgId}/invitation`));
      const invitation: Invitation = {
        createTime: Timestamp.now(),
        creatorId: userProfile.itemId,
        itemId: invitationRef.id,
        email,
        isTest,
        name,
        orgId,
        orgName,
        ...(personId && { personId }),
      };

      await setDoc(invitationRef, invitation);
    } catch (error) {
      console.error('Error adding invitation:', error);
    }
  };

  // *************** Application ***************
  const addApplication = async (
    orgId: string,
    name: string,
    mailingAddress: string,
    email: string,
    ghin: string,
    phone: string,
    isApproved = false,
    isTest = false
  ): Promise<void> => {
    try {
      // Get org details
      const orgRef = doc(firestore, 'org/' + orgId);
      const orgDoc = await getDoc(orgRef);
      const orgName = orgDoc.data()?.name || '';

      // Create application
      const applicationRef = doc(applicationsCol);
      const application: Application = {
        createTime: Timestamp.now(),
        creatorId: userProfile?.itemId || '',
        itemId: applicationRef.id,
        datetime: Timestamp.now(),
        email,
        details: {
          itemId: applicationRef.id,
          createTime: Timestamp.now(),
          creatorId: userProfile?.itemId || '',
          name,
          ...(mailingAddress && { mailingAddress }),
          ...(phone && { phone }),
        },
        ghin,
        isActive: false,
        level: 'member',
        name,
        isTest,
        orgId,
        orgName,
        isApproved,
        isRejected: false,
        isPaid: false,
      };

      await setDoc(applicationRef, application);
    } catch (error) {
      console.error('Error adding application:', error);
      // TODO: Consider proper error handling since this can be called by anyone
    }
  };

  const deleteApplication = async (appId: string): Promise<void> => {
    if (!appId) {
      console.error('No application ID provided');
      return;
    }

    try {
      const applicationRef = doc(applicationsCol, appId);
      await deleteDoc(applicationRef);
    } catch (error) {
      console.error('Error deleting application:', error);
    }
  };

  const updateApplication = async (appId: string, isApproved: boolean, isRejected: boolean) => {
    if (!appId) {
      console.error('No application ID provided');
      return;
    }

    try {
      // reference to the document to update
      const applicationRef = doc(applicationsCol, appId);

      // Update the value of the application
      await setDoc(applicationRef, { isApproved: isApproved, isRejected: isRejected }, { merge: true });
    } catch (error) {
      alert(error);
    }
  };

  // *************** Course functions ****************
  const getCourse = async (courseId: string): Promise<Course | null> => {
    try {
      const courseRef = doc(firestore, 'course', courseId);
      const courseDoc = await getDoc(courseRef);

      if (!courseDoc.exists()) {
        console.error('Course not found:', courseId);
        return null;
      }

      return courseDoc.data() as Course;
    } catch (error) {
      console.error('Error fetching course:', error);
      return null;
    }
  };

  const addCourse = async (course: Course, isTest = false): Promise<Course | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }

    if (!isGod) {
      console.error('Permission denied: only admins can add courses');
      return null;
    }

    try {
      const courseRef = doc(collection(firestore, 'course'));

      const newCourse = {
        ...course,
        creatorId: userProfile.itemId,
        createTime: CurrentDateTime(),
        itemId: courseRef.id,
        isTest,
      };

      await setDoc(courseRef, newCourse);
      return newCourse;
    } catch (error) {
      console.error('Error adding course:', error);
      return null;
    }
  };

  const deleteCourse = async (course: Course): Promise<void> => {
    if (!isGod) {
      console.error('Permission denied: only admins can delete courses');
      return;
    }

    if (!course.itemId) {
      console.error('Course itemId not found');
      return;
    }

    try {
      const courseRef = doc(firestore, 'course/' + course.itemId);
      await deleteDoc(courseRef);
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  const updateCourse = async (course: Partial<Course>): Promise<void> => {
    if (!course.itemId) {
      console.error('Course itemId not found');
      return;
    }

    // Only check org admin rights if an orgId is specified
    if (!isGod && course.orgId && !isOrgAdmin(course.orgId)) {
      console.error("Permission denied: must be admin of course's organization");
      return;
    }

    try {
      const courseRef = doc(firestore, 'course/' + course.itemId);

      // First get the existing course to preserve fields
      const existingCourse = await getDoc(courseRef);
      if (!existingCourse.exists()) {
        console.error('Course not found');
        return;
      }

      const existingData = existingCourse.data();
      const updatedCourse = {
        ...existingData,
        ...course,
        isTest: course.isTest ?? existingData.isTest, // Explicitly preserve isTest
      };

      await setDoc(courseRef, updatedCourse);
      console.log('Updated course with isTest:', updatedCourse.isTest); // Debug log
    } catch (error) {
      console.error('Error updating course:', error);
    }
  };

  // *************** Event Items ****************
  const getEvent = async (orgId: string, eventId: string): Promise<Event | null> => {
    try {
      const eventRef = doc(firestore, `org/${orgId}/event/${eventId}`);
      const eventDoc = await getDoc(eventRef);

      if (!eventDoc.exists()) {
        console.error('Event not found:', eventId);
        return null;
      }

      return eventDoc.data() as Event;
    } catch (error) {
      console.error('Error fetching event:', error);
      return null;
    }
  };

  const addEvent = async (event: Event, isTest = false): Promise<Event | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }

    if (!event.leagueId || !event.orgId) {
      console.error('Missing required event fields:', event.name);
      return null;
    }

    if (!isLeagueAdmin(event.leagueId, event.orgId)) {
      console.error('Permission denied. User:', userProfile.itemId, 'Event:', event.itemId);
      return null;
    }

    try {
      const eventRef = doc(collection(firestore, `org/${event.orgId}/event`));

      const newEvent = {
        ...event,
        createTime: CurrentDateTime(),
        creatorId: userProfile.itemId,
        datetime: event.days[0].datetime,
        itemId: eventRef.id,
        isTest,
      };

      await setDoc(eventRef, newEvent);
      return newEvent;
    } catch (error) {
      console.error('Error adding event:', error);
      return null;
    }
  };

  const deleteEvent = async (event: Event): Promise<void> => {
    if (!event.itemId || !event.orgId) {
      console.error('Event itemId or orgId not found:', event.itemId, event.orgId);
      return;
    }

    if (!isLeagueAdmin(event.leagueId, event.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'Event:', event.itemId);
      return;
    }

    try {
      const eventRef = doc(firestore, `org/${event.orgId}/event/${event.itemId}`);
      await deleteDoc(eventRef);
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const updateEvent = async (event: Partial<Event>): Promise<void> => {
    if (!event.orgId || !event.itemId || !event.leagueId) {
      console.error('Missing required fields:', {
        orgId: event.orgId,
        itemId: event.itemId,
        leagueId: event.leagueId,
      });
      return;
    }

    if (!isLeagueAdmin(event.leagueId, event.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'Event:', event.itemId);
      return;
    }

    try {
      // extract the orgId and itemId from the event object as changing them is not allowed
      const { orgId, itemId, leagueId, ...eventUpdates } = event;
      const eventRef = doc(firestore, `org/${orgId}/event/${itemId}`);

      await updateDoc(eventRef, {
        ...eventUpdates,
        datetime: event.days ? event.days[0].datetime : Timestamp.now(),
      });
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  /*
  const copyEventToOrg = async (eventId: string, orgId: string) => {
    console.log("Copying event to org: " + eventId + " " + orgId);
    const eventRef = doc(eventsCol, eventId);
    const eventDoc = await getDoc(eventRef);
    try {
      if (eventDoc.exists()) {
        const event = eventDoc.data() as Event;
        const orgEventRef = doc(orgEventsCol, eventId);
        await setDoc(orgEventRef, event);
        const qReg = query(
          collection(firestore, 'event/' + event.itemId + '/reg'),
          orderBy('datetime')
        ) as Query<Registration>;
        const regSnapshot = await getDocs(qReg);
        regSnapshot.forEach(async (reg) => {
          const regRef = doc(firestore, "org/" + orgId + "/event/" + orgEventRef.id + "/reg/" + reg.id);
          const regData = reg.data() as Registration;
          regData.eventId = orgEventRef.id;
          await setDoc(regRef, regData);
        });
        return true;
      } else {
        console.log("No such document!");
          return false;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };
  */

  // *************** Inquiry Items ****************
  const addInquiry = async (name: string, email: string, phone: string): Promise<void> => {
    try {
      const inquiryRef = doc(inquiriesCol);
      const inquiry: Inquiry = {
        datetime: Timestamp.now(),
        name,
        email,
        phone,
        status: 'new',
      };

      await setDoc(inquiryRef, inquiry);
    } catch (error) {
      console.error('Error adding inquiry:', error);
    }
  };

  // *************** League functions ****************
  const getLeague = async (orgId: string, leagueId: string): Promise<League | null> => {
    if (!orgId || !leagueId) {
      console.error('Missing required fields:', { orgId, leagueId });
      return null;
    }

    try {
      const leagueRef = doc(firestore, `org/${orgId}/league/${leagueId}`);
      const leagueDoc = await getDoc(leagueRef);

      if (!leagueDoc.exists()) {
        console.error('League not found:', leagueId);
        return null;
      }

      return leagueDoc.data() as League;
    } catch (error) {
      console.error('Error fetching league:', error);
      return null;
    }
  };

  const addLeague = async (league: League, isTest = false): Promise<League | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }

    if (!league.orgId) {
      console.error('League orgId not found');
      return null;
    }

    if (!isOrgAdmin(league.orgId)) {
      console.error('Permission denied: must be admin of organization');
      return null;
    }

    try {
      const org = await getOrg(league.orgId);
      if (!org) {
        console.error('Org not found:', league.orgId);
        return null;
      }

      const leagueRef = doc(collection(firestore, `org/${org.itemId}/league`));
      const newLeague: League = {
        ...league,
        creatorId: userProfile.itemId,
        createTime: CurrentDateTime(),
        itemId: leagueRef.id,
        isTest,
        orgId: org.itemId,
        orgName: org.name,
      };

      await setDoc(leagueRef, newLeague);
      return newLeague;
    } catch (error) {
      console.error('Error adding league:', error);
      return null;
    }
  };

  const deleteLeague = async (league: League): Promise<void> => {
    if (!league.itemId || !league.orgId) {
      console.error('League itemId or orgId not found:', league.itemId, league.orgId);
      return;
    }

    if (league.isLocked && !isGod) {
      console.error('Cannot modify locked league. user: ', userProfile?.itemId);
      return;
    }

    if (!isOrgAdmin(league.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'League:', league.itemId);
      return;
    }

    try {
      const leagueRef = doc(firestore, `org/${league.orgId}/league/${league.itemId}`);
      await deleteDoc(leagueRef);
    } catch (error) {
      console.error('Error deleting league:', error);
    }
  };

  const updateLeague = async (league: Partial<League>): Promise<void> => {
    if (!league.orgId || !league.itemId) {
      console.error('Missing required fields:', { orgId: league.orgId, itemId: league.itemId });
      return;
    }

    if (league.isLocked && !isGod) {
      console.error('Cannot modify locked league. user: ', userProfile?.itemId);
      return;
    }

    if (!isLeagueAdmin(league.itemId, league.orgId)) {
      console.error('Permission denied: must be admin of organization');
      return;
    }

    try {
      // extract the orgId and itemId from the league object as changing them is not allowed
      const { orgId, itemId, ...leagueUpdates } = league;
      const leagueRef = doc(firestore, `org/${orgId}/league/${itemId}`);
      await setDoc(leagueRef, { ...leagueUpdates }, { merge: true });
    } catch (error) {
      console.error('Error updating league:', error);
    }
  };

  // *************** Season functions ****************
  const getSeason = async (league: League, seasonId: string): Promise<Season | null> => {
    if (!seasonId) {
      console.error('No season ID provided');
      return null;
    }

    const orgId = memberships?.find((membership) => membership.seasons?.includes(seasonId))?.orgId;

    if (!orgId) {
      console.error("Season not found in user's memberships:", seasonId);
      return null;
    }

    try {
      const seasonRef = doc(firestore, `org/${orgId}/season/${seasonId}`);
      const seasonDoc = await getDoc(seasonRef);

      if (!seasonDoc.exists()) {
        console.error('Season document not found:', seasonId);
        return null;
      }

      return seasonDoc.data() as Season;
    } catch (error) {
      console.error('Error fetching season:', error);
      return null;
    }
  };

  /*
  const getLeagueSeasons = async (league: League): Promise<Season[] | null | undefined> => {
    try {
      if (league && league.orgId && userProfile && (isGod || (userProfile.orgIds && userProfile.orgIds.includes(league.orgId)))) {
        const seasonsCol = collection(firestore, "org/" + league.orgId + "/season");  
        const q = query(
          seasonsCol,
          and(
            ...(isGod ? [] : [where("orgId", "in", userProfile.orgIds)]),
            where("leagueId", "==", league.itemId),
          ));
        const seasonsSnapshot = await getDocs(q);
        if (seasonsSnapshot.size > 0) {
          const seasons: Season[] = [];
          seasonsSnapshot.forEach((season) => {
            seasons.push(season.data() as Season);
          });
          return seasons;
        } else {
          return null;
        }
      } else {
        alert("getLeagueSeasons: no orgId for league: " + league.itemId);
        return undefined;
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
    return undefined;
  }; */

  // TODO: Should we use Partial for other Adds? Since creatorId, createTime and itemId are all added at this layer...
  const addSeason = async (season: Partial<Season>): Promise<Season | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }

    if (!season?.orgId) {
      console.error('Season orgId not found');
      return null;
    }

    try {
      const org = await getOrg(season.orgId);
      if (!org) {
        console.error('Org not found:', season.orgId);
        return null;
      }

      const seasonRef = doc(firestore, `org/${org.itemId}/season/${season.itemId}`);
      const newSeason = {
        ...season,
        creatorId: userProfile.itemId,
        createTime: CurrentDateTime(),
        itemId: seasonRef.id,
        orgId: org.itemId,
        orgName: org.name,
      };

      await setDoc(seasonRef, newSeason);
      return newSeason as Season;
    } catch (error) {
      console.error('Error adding season:', error);
      return null;
    }
  };

  const addStandingToSeason = async (season: Season, standing: Result): Promise<void> => {
    if (!season.orgId) {
      console.error('Season orgId not found');
      return;
    }

    if (!isLeagueAdmin(season.leagueId, season.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'Season:', season.itemId);
      return;
    }

    try {
      const seasonRef = doc(firestore, `org/${season.orgId}/season/${season.itemId}`);
      const seasonDoc = await getDoc(seasonRef);

      if (!seasonDoc.exists()) {
        console.error('Season not found:', season.itemId);
        return;
      }

      const currentSeason = seasonDoc.data() as Season;
      const standings = [...(currentSeason.standings || []), standing];

      await setDoc(seasonRef, { ...currentSeason, standings }, { merge: true });
    } catch (error) {
      console.error('Error updating season standings:', error);
    }
  };

  const deleteSeason = async (season: Season): Promise<void> => {
    if (!season.orgId || !season.itemId) {
      console.error('Season orgId or itemId not found');
      return;
    }

    if (!isLeagueAdmin(season.leagueId, season.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'Season:', season.itemId);
      return;
    }

    try {
      const seasonRef = doc(firestore, `org/${season.orgId}/season/${season.itemId}`);
      await deleteDoc(seasonRef);
    } catch (error) {
      console.error('Error deleting season:', error);
    }
  };

  const updateSeason = async (season: Partial<Season>) => {
    try {
      // These fields can't be changed via update because it would move them to entirely different structures
      // with possibly different sets of games and members.
      const { orgId, itemId, leagueId, ...seasonUpdates } = season;
      if (!orgId || !itemId || !leagueId) {
        console.error('Missing required fields:', {
          orgId: season.orgId,
          itemId: season.itemId,
          leagueId: season.leagueId,
        });
        return;
      }

      if (!isLeagueAdmin(leagueId, orgId)) {
        console.error('Permission denied: must be admin of league');
        return;
      }

      // with possibly different sets of games and members.
      const seasonRef = doc(firestore, `org/${orgId}/season/${itemId}`);
      await setDoc(seasonRef, { ...seasonUpdates }, { merge: true });
    } catch (error) {
      console.error('Error updating season:', error);
    }
  };

  // *************** Membership functions ****************
  const updateMembership = async (membership: Partial<Membership>): Promise<void> => {
    if (!membership.orgId || !membership.itemId) {
      console.error('Missing required fields:', {
        orgId: membership.orgId,
        itemId: membership.itemId,
      });
      return;
    }

    try {
      const { orgId, itemId, ...membershipUpdates } = membership;
      const membershipRef = doc(firestore, `org/${orgId}/membership/${itemId}`);
      await setDoc(membershipRef, { ...membershipUpdates }, { merge: true });
    } catch (error) {
      console.error('Error updating membership:', error);
    }
  };

  const addMembership = async (membership: Partial<Membership>): Promise<Membership | null> => {
    if (!membership.orgId || !membership.itemId) {
      console.error('Missing required fields:', {
        orgId: membership.orgId,
        itemId: membership.itemId,
      });
      return null;
    }

    try {
      const membershipRef = doc(firestore, `org/${membership.orgId}/membership/${membership.itemId}`);
      const newMembership = {
        ...membership,
        createTime: CurrentDateTime(),
        creatorId: userProfile?.itemId || '',
      };

      await setDoc(membershipRef, newMembership);
      return newMembership as Membership;
    } catch (error) {
      console.error('Error adding membership:', error);
      return null;
    }
  };

  const addOrgMembership = async (org: Org, personId: string): Promise<Membership | null> => {
    try {
      const personRef = doc(firestore, 'profile', personId);
      const personDoc = await getDoc(personRef);
      const person = personDoc.data() as Person;

      if (!person) {
        console.error('Member profile not found:', personId);
        return null;
      }

      const batch = writeBatch(firestore);
      let newMembership: Membership | null = null;

      // Handle membership document
      const membershipRef = doc(firestore, `org/${org.itemId}/membership/${personId}`);
      const membershipDoc = await getDoc(membershipRef);

      if (membershipDoc.exists()) {
        const membership = membershipDoc.data() as Membership;
        if (!membership.isMember || !membership.isActive) {
          newMembership = { ...membership, isMember: true, isActive: true };
          batch.update(membershipRef, { ...newMembership });
        } else {
          console.log('Membership already exists:', person.itemId);
        }
      } else {
        newMembership = {
          createTime: CurrentDateTime(),
          creatorId: userProfile?.itemId || '',
          isMember: true,
          isActive: true,
          isAdmin: false,
          isPending: false,
          itemId: personId,
          name: person.name,
          orgId: org.itemId,
        };
        batch.set(membershipRef, newMembership);
      }

      // Handle orgIds and inactiveOrgIds arrays
      let needsProfileUpdate = false;
      const inactiveOrgIds = [...(person.inactiveOrgIds || [])];
      const orgIds = [...(person.orgIds || [])];

      // Remove from inactive if present
      const inactiveIndex = inactiveOrgIds.indexOf(org.itemId);
      if (inactiveIndex !== -1) {
        inactiveOrgIds.splice(inactiveIndex, 1);
        needsProfileUpdate = true;
      }

      // Add to active if not present
      if (!orgIds.includes(org.itemId)) {
        orgIds.push(org.itemId);
        needsProfileUpdate = true;
      }

      // Update profile if either array changed
      if (needsProfileUpdate) {
        const profileRef = doc(firestore, 'profile/' + personId);
        batch.set(profileRef, { ...person, orgIds, inactiveOrgIds }, { merge: true });
      }

      await batch.commit();
      return newMembership;
    } catch (error) {
      console.error('Error adding org membership:', error);
      return null;
    }
  };

  const acceptOrgMembership = async (org: Org, personId?: string): Promise<void> => {
    const acceptingPersonId = personId || userProfile?.itemId;

    if (!acceptingPersonId) {
      console.error('No person ID provided and no user profile found');
      return;
    }

    if (!org.itemId) {
      console.error('Org itemId not found');
      return;
    }

    try {
      const membershipRef = doc(firestore, `org/${org.itemId}/membership/${acceptingPersonId}`);
      await updateDoc(membershipRef, { isPending: false });
    } catch (error) {
      console.error('Error accepting org membership:', error);
    }
  };

  const deactivateOrgMembership = async (org: Org, personId: string): Promise<void> => {
    try {
      const personRef = doc(firestore, 'profile', personId);
      const personDoc = await getDoc(personRef);
      const person = personDoc.data() as Person;

      if (!person) {
        console.error('Person not found:', personId);
        return;
      }

      const batch = writeBatch(firestore);

      // Update membership status
      const membershipRef = doc(firestore, `org/${org.itemId}/membership/${personId}`);
      batch.update(membershipRef, { isActive: false });

      // Handle orgIds and inactiveOrgIds arrays
      let needsProfileUpdate = false;
      const inactiveOrgIds = [...(person.inactiveOrgIds || [])];
      const orgIds = [...(person.orgIds || [])];

      // Remove from active if present
      const activeIndex = orgIds.indexOf(org.itemId);
      if (activeIndex !== -1) {
        orgIds.splice(activeIndex, 1);
        needsProfileUpdate = true;
      }

      // Add to inactive if not present
      if (!inactiveOrgIds.includes(org.itemId)) {
        inactiveOrgIds.push(org.itemId);
        needsProfileUpdate = true;
      }

      // Update profile if either array changed
      if (needsProfileUpdate) {
        const profileRef = doc(firestore, 'profile/' + personId);
        batch.set(profileRef, { ...person, orgIds, inactiveOrgIds }, { merge: true });
      }

      await batch.commit();
    } catch (error) {
      console.error('Error deactivating org membership:', error);
    }
  };

  const deleteOrgMembership = async (org: Org, personId: string, isPermanent: boolean = false) => {
    try {
      const personRef = doc(firestore, 'profile', personId);
      const personDoc = await getDoc(personRef);
      const person = personDoc.data() as Person;
      if (person) {
        const batch = writeBatch(firestore);
        const membershipRef = doc(firestore, 'org/' + org.itemId + '/membership/' + personId);
        if (isPermanent) {
          batch.delete(membershipRef);
        } else {
          batch.update(membershipRef, { isMember: false, isActive: false });
        }

        // Handle orgIds and inactiveOrgIds arrays
        let needsProfileUpdate = false;
        const inactiveOrgIds = [...(person.inactiveOrgIds || [])];
        const orgIds = [...(person.orgIds || [])];

        // Remove from active if present
        const activeIndex = orgIds.indexOf(org.itemId);
        if (activeIndex !== -1) {
          orgIds.splice(activeIndex, 1);
          needsProfileUpdate = true;
        }

        // Remove from inactive if present
        const inactiveIndex = inactiveOrgIds.indexOf(org.itemId);
        if (inactiveIndex !== -1) {
          inactiveOrgIds.splice(inactiveIndex, 1);
          needsProfileUpdate = true;
        }

        // Update profile if either array changed
        if (needsProfileUpdate) {
          const profileRef = doc(firestore, 'profile/' + personId);
          batch.set(profileRef, { ...person, orgIds, inactiveOrgIds }, { merge: true });
        }
      } else {
        throw new Error('Person not found: ' + personId);
      }
    } catch (error) {
      alert(error);
    }
  };

  const addLeagueMembership = async (league: League, memberId: string): Promise<void> => {
    try {
      const membershipRef = doc(firestore, `org/${league.orgId}/membership/${memberId}`);
      const membershipDoc = await getDoc(membershipRef);

      if (!membershipDoc.exists()) {
        console.error('Membership not found:', memberId);
        return;
      }

      const membership = membershipDoc.data() as Membership;

      if (membership.leagues?.includes(league.itemId)) {
        console.log('Membership already has this league.');
        return;
      }

      const updateLeagues = {
        leagues: arrayUnion(membership.leagues, league.itemId),
      };

      // Update both membership and profile documents
      const batch = writeBatch(firestore);
      batch.update(membershipRef, updateLeagues);

      const profileRef = doc(firestore, 'profile', memberId);
      batch.update(profileRef, { leagues: arrayUnion(league.itemId) });

      await batch.commit();
      console.log('Membership updated with league:', league.itemId);
    } catch (error) {
      console.error('Error adding league membership:', error);
    }
  };

  // *************** Event Registration ****************
  const createNewRegistration = (eventId: string, userId: string, userName: string): Registration => ({
    eventId,
    playerId: userId,
    playerName: userName,
    comment: '',
    datetime: CurrentDateTime(),
    group: 0,
    isEighteen: true,
    isPlayingGame: true,
    isRiding: true,
    isRegistered: false,
    isWaitListed: false,
  });

  const addEventRegistration = async (
    event: Event,
    isRegistering: boolean,
    personToRegister?: Person
  ): Promise<Registration | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }

    if (!event) {
      console.error('Event is null');
      return null;
    }

    try {
      let regUserId: string;
      let regUserName: string;

      if (personToRegister) {
        if (!isOrgAdmin(event.orgId) && personToRegister.itemId !== userProfile.itemId) {
          console.error('User not authorized to register for another user');
          return null;
        }
        regUserId = personToRegister.itemId;
        regUserName = personToRegister.name;
      } else {
        regUserId = userProfile.itemId;
        regUserName = userProfile.name;
      }

      const regRef = doc(firestore, `org/${event.orgId}/event/${event.itemId}/reg/${regUserId}`);
      const regDoc = await getDoc(regRef);

      let reg: Registration;
      if (regDoc.exists()) {
        reg = {
          ...(regDoc.data() as Registration),
          datetime: CurrentDateTime(),
          isRegistered: isRegistering,
        };
      } else {
        reg = {
          ...createNewRegistration(event.itemId, regUserId, regUserName),
          isRegistered: isRegistering,
        };
      }

      await setDoc(regRef, reg, { merge: true });
      return reg;
    } catch (error) {
      console.error('Error adding event registration:', error);
      return null;
    }
  };

  // TODO: This can be merged with registerForEvent
  const updateEventRegistration = async (
    event: Event,
    playerId: string,
    registration: Partial<Registration>
  ): Promise<void> => {
    if (!userProfile) {
      console.error('User profile not found');
      return;
    }

    if (!event) {
      console.error('Event not found');
      return;
    }

    // A player can update their own registration or an admin can update any registration.
    if (userProfile.itemId !== playerId && !isOrgAdmin(event.orgId)) {
      console.error('Permission denied: must be player or admin');
      return;
    }

    try {
      console.log('Updating registration:', {
        eventId: event.itemId,
        playerId,
        isRegistered: registration.isRegistered,
      });

      const regRef = doc(firestore, `org/${event.orgId}/event/${event.itemId}/reg/${playerId}`);
      await setDoc(regRef, { ...registration, playerId }, { merge: true });
    } catch (error) {
      console.error('Error updating event registration:', error);
    }
  };

  // *************** User Profile functions ****************
  const getPerson = async (id: string): Promise<Person | null> => {
    try {
      if (userProfile && userProfile.itemId === id) {
        return userProfile;
      }

      const profileRef = doc(firestore, 'profile', id);
      const profileDoc = await getDoc(profileRef);

      if (profileDoc.exists()) {
        return profileDoc.data() as Person;
      }

      return null;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return null;
    }
  };

  const updatePersonDoc = async (id: string, person: Partial<Person>): Promise<void> => {
    try {
      const personRef = doc(firestore, 'profile', id);
      await setDoc(personRef, { ...person }, { merge: true });
      console.log('Profile updated:', person.name);
    } catch (error) {
      console.error('Error updating profile document:', error);
    }
  };

  const updatePerson = async (id: string, person: Partial<Person>, picFile?: File | null): Promise<void> => {
    if (!user) {
      console.error('User not found');
      return;
    }

    try {
      if (picFile) {
        // Upload new profile picture
        const pictureRef = ref(imagesRef, person.picture);
        await uploadBytes(pictureRef, picFile);
        console.log('Picture uploaded');

        await updatePersonDoc(id, person);
      } else if (picFile === null) {
        // Delete existing profile picture
        const pictureRef = ref(imagesRef, person.itemId);
        await deleteObject(pictureRef);
        console.log('Picture deleted');

        await updatePersonDoc(id, {
          ...person,
          picture: '',
          pictureName: '',
        });
      } else {
        // Just update profile without picture changes
        await updatePersonDoc(id, person);
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };

  const updatePersonDetails = async (id: string, details: Partial<PersonDetails>): Promise<void> => {
    try {
      const personDetailsRef = doc(firestore, 'profile', id, 'details', id);
      await setDoc(personDetailsRef, { ...details }, { merge: true });
    } catch (error) {
      console.error('Error updating person details:', error);
    }
  };

  const updateGHINIndex = async (ghin: string, index: string): Promise<void> => {
    try {
      const q = query(personsCol, where('ghin', '==', ghin));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0];
        await updatePerson(doc.id, { index });
      }
    } catch (error) {
      console.error('Error updating user index:', error);
    }
  };

  // *************** General firestore functions ****************
  const removeCollectionField = async (docCollection: string, fieldToRemove: string): Promise<void> => {
    if (!isGod) {
      throw new Error('Permission denied: User is not God');
    }

    try {
      const segments = docCollection.split('/');
      const isSubcollection = segments.length > 1;

      if (isSubcollection) {
        const [parentCollection, wildcard, subcollection] = segments;
        if (wildcard !== '{*}') {
          throw new Error('Use {*} to remove field from all documents in subcollection');
        }

        // Get all parent documents
        const parentDocs = await getDocs(collection(firestore, parentCollection));
        if (parentDocs.empty) {
          throw new Error(`Parent collection ${parentCollection} is empty`);
        }

        // Process each parent's subcollection
        for (const parentDoc of parentDocs.docs) {
          const subRef = collection(firestore, parentCollection, parentDoc.id, subcollection);
          const subDocs = await getDocs(subRef);

          if (!subDocs.empty) {
            const batch = writeBatch(firestore);
            subDocs.forEach((document) => {
              batch.update(document.ref, {
                [fieldToRemove]: deleteField(),
              });
            });
            await batch.commit();
          }
        }
      } else {
        // Original code for top-level collections
        const querySnapshot = await getDocs(collection(firestore, docCollection));
        if (querySnapshot.empty) {
          throw new Error(`Collection ${docCollection} is empty`);
        }

        const batch = writeBatch(firestore);
        querySnapshot.forEach((document) => {
          batch.update(document.ref, {
            [fieldToRemove]: deleteField(),
          });
        });
        await batch.commit();
      }

      console.log(`Successfully removed field ${fieldToRemove} from ${docCollection}`);
    } catch (error) {
      console.error('Error removing field:', error);
      throw error;
    }
  };

  const updateCollectionField = async (docCollection: string, fieldToUpdate: string, value: unknown): Promise<void> => {
    if (!isGod) {
      throw new Error('Permission denied: User is not God');
    }

    try {
      const segments = docCollection.split('/');
      const isSubcollection = segments.length > 1;

      if (isSubcollection) {
        const [parentCollection, wildcard, subcollection] = segments;
        if (wildcard !== '{*}') {
          throw new Error('Use {*} to update all documents in subcollection');
        }

        // Get all parent documents
        const parentDocs = await getDocs(collection(firestore, parentCollection));
        if (parentDocs.empty) {
          throw new Error(`Parent collection ${parentCollection} is empty`);
        }

        // Process each parent's subcollection
        for (const parentDoc of parentDocs.docs) {
          const subRef = collection(firestore, parentCollection, parentDoc.id, subcollection);
          const subDocs = await getDocs(subRef);

          if (!subDocs.empty) {
            // Type validation using first document
            const sampleDoc = subDocs.docs[0].data();
            const existingValue = sampleDoc[fieldToUpdate];

            if (existingValue !== undefined) {
              const existingType = typeof existingValue;
              const newType = typeof value;

              if (existingType !== newType) {
                if (existingValue instanceof Timestamp && (value instanceof Date || value instanceof Timestamp)) {
                  value = value instanceof Date ? Timestamp.fromDate(value) : value;
                } else {
                  throw new Error(
                    `Type mismatch: Field "${fieldToUpdate}" expects ${existingType}, but got ${newType}`
                  );
                }
              }
            }

            // Create update object outside the loop
            const updateData = {
              [fieldToUpdate]: value as FieldValue | Partial<unknown> | undefined,
            };

            // Update all documents in this subcollection
            const batch = writeBatch(firestore);
            subDocs.forEach((document) => {
              batch.update(document.ref, updateData);
            });
            await batch.commit();
          }
        }
      } else {
        // Original code for top-level collections
        const querySnapshot = await getDocs(collection(firestore, docCollection));
        if (querySnapshot.empty) {
          throw new Error(`Collection ${docCollection} is empty`);
        }

        // ... rest of existing validation and update code
        const sampleDoc = querySnapshot.docs[0].data();
        const existingValue = sampleDoc[fieldToUpdate];

        if (existingValue !== undefined) {
          const existingType = typeof existingValue;
          const newType = typeof value;

          if (existingType !== newType) {
            if (existingValue instanceof Timestamp && (value instanceof Date || value instanceof Timestamp)) {
              value = value instanceof Date ? Timestamp.fromDate(value) : value;
            } else {
              throw new Error(`Type mismatch: Field "${fieldToUpdate}" expects ${existingType}, but got ${newType}`);
            }
          }
        }

        const batch = writeBatch(firestore);
        querySnapshot.forEach((document) => {
          const docRef = doc(firestore, docCollection, document.id);
          batch.update(docRef, {
            [fieldToUpdate]: value as FieldValue | Partial<unknown> | undefined,
          });
        });
        await batch.commit();
      }

      console.log(`Successfully updated field ${fieldToUpdate} to ${value} in ${docCollection}`);
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const getDefaultValue = <T,>(value: T[keyof T], key: keyof T): T[keyof T] => {
    if (value instanceof Timestamp) {
      return serverTimestamp() as T[keyof T];
    } else if (typeof value === 'string') {
      return '' as T[keyof T];
    } else if (typeof value === 'number') {
      return 0 as T[keyof T];
    } else if (typeof value === 'boolean') {
      return false as T[keyof T];
    }
    return value;
  };

  const convertValue = <T,>(key: keyof T, value: T[keyof T], parentDoc: DocumentSnapshot): T[keyof T] => {
    const parentData = parentDoc.data();

    // Common fields first
    if (key === 'itemId') {
      return parentDoc.id as T[keyof T];
    } else if (key === 'creatorId') {
      return userProfile?.itemId as T[keyof T];
    } else if (key === 'createTime') {
      return serverTimestamp() as T[keyof T];
    }
    // Copy matching parent fields with type checking
    if (parentData && key in parentData) {
      const parentValue = parentData[key as string];
      if (typeof parentValue === typeof value) {
        return parentValue as T[keyof T];
      }
    }
    // Default values for remaining fields
    return getDefaultValue(value, key);
  };

  const createSubcollectionForAll = async <T extends { itemId?: string }>(
    collectionPath: string,
    emptyDoc: T
  ): Promise<number> => {
    const [parentCollection, , subcollectionName] = collectionPath.split('/');

    if (!parentCollection || !subcollectionName) {
      throw new Error('Invalid collection path. Format should be: collection/{doc}/subcollection');
    }

    console.log('emptyDoc', emptyDoc);
    const parentCollectionRef = collection(firestore, parentCollection);
    const querySnapshot = await getDocs(parentCollectionRef);

    const batch = writeBatch(firestore);

    querySnapshot.forEach((parentDoc) => {
      const subcollectionRef = collection(firestore, parentCollection, parentDoc.id, subcollectionName);
      const newDocRef = doc(subcollectionRef, parentDoc.id);

      const newDoc = { ...emptyDoc } as { [K in keyof T]: T[K] };

      (Object.keys(newDoc) as Array<keyof T>).forEach((key) => {
        newDoc[key] = convertValue(key, newDoc[key], parentDoc);
      });
      console.log('newDoc', newDoc);
      batch.set(newDocRef as DocumentReference<T>, newDoc);
    });

    await batch.commit();
    return querySnapshot.size;
  };

  const removeSubcollectionForAll = async (collectionPath: string): Promise<number> => {
    // Parse the collection path
    const [parentCollection, , subcollectionName] = collectionPath.split('/');

    if (!parentCollection || !subcollectionName) {
      throw new Error('Invalid collection path. Format should be: collection/{doc}/subcollection');
    }

    // Get all documents from parent collection
    const parentCollectionRef = collection(firestore, parentCollection);
    const querySnapshot = await getDocs(parentCollectionRef);

    // Delete subcollection for each document
    const batch = writeBatch(firestore);
    const deletePromises = querySnapshot.docs.map(async (parentDoc) => {
      const subcollectionRef = collection(firestore, parentCollection, parentDoc.id, subcollectionName);
      const subcollectionDocs = await getDocs(subcollectionRef);

      subcollectionDocs.forEach((doc) => {
        batch.delete(doc.ref);
      });
    });

    // Wait for all subcollection queries to complete
    await Promise.all(deletePromises);
    await batch.commit();

    return querySnapshot.size;
  };

  const values = {
    // User Profile functions
    personsCol,
    getPerson,
    updatePerson,
    updatePersonDetails,
    updateGHINIndex,

    // User state
    userProfile,
    isUserActive: !!userProfile,
    isUserLoading,
    isAdminDisabled,
    adminDisabled,

    // Authorization
    isEventAdmin,
    isLeagueAdmin,
    isOrgAdmin,
    isOrgMember,
    canCreateEvents,
    canEditPerson,
    canEditPersonDetails,
    areInASharedOrg,
    hasAdminRightsOverPerson,
    getAdminOrgs,
    getMemberOrgs,

    // Organization management
    adminLeagues,
    getOrg,
    addOrg,
    deleteOrg,
    updateOrg,

    // Applications and Inquiries
    applicationsCol,
    inquiriesCol,
    addInquiry,
    addApplication,
    deleteApplication,
    updateApplication,
    addInvitation,

    // Membership management
    memberships,
    addMembership,
    updateMembership,
    addOrgMembership,
    acceptOrgMembership,
    deleteOrgMembership,
    deactivateOrgMembership,
    addLeagueMembership,

    // League management
    getLeague,
    addLeague,
    deleteLeague,
    updateLeague,

    // Season management
    getSeason,
    addSeason,
    addStandingToSeason,
    deleteSeason,
    updateSeason,

    // Event management
    getEvent,
    addEvent,
    deleteEvent,
    updateEvent,
    addEventRegistration,
    updateEventRegistration,

    // Course management
    getCourse,
    addCourse,
    deleteCourse,
    updateCourse,

    // Collection utilities
    removeCollectionField,
    updateCollectionField,
    createSubcollectionForAll,
    removeSubcollectionForAll,
  };

  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};
